import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import FeaturedProducts from "./FeaturedProducts";
import { useNavigate } from "react-router-dom";
import Loading from "./common/Loading";
import Slider from "react-slick";
import PrevArrow from "./common/PrevArrow";
import NextArrow from "./common/NextArrow";
import MainMap from "./map/MainMap";


function Main() {
  const navigate = useNavigate();
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState([true, true, true, true]);
  const [mapLoading, setMapLoading] = useState(true);
  // const [random, setRandom] = useState([]);

  // 슬라이드 세팅
  const settings = {
    className: "center",
    arrow: true,
    infinite: true,
    slidesToShow: 5,
    speed: 400,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  const handleImageLoad = (index) => {
    setIsLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = false;
      return newLoading;
    });
  };

  useEffect(() => {
    getProrudct();
  }, []);

  const handleClick = (product) => {
    navigate("/product-detail", {
      state: { product: product },
    });
  };

  const getProrudct = async () => {
    try {
      const response = await fetch(
        "https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            echanggoYn: "Y",
            offset: 0,
            pageNumber: 0,
            pageSize: 100,
            paged: true,
          }),
        }
      );

      const prdList = await response.json();
      // const prdListFiltered = prdList.content.filter((item) => item.prdImgFlpth !== null);
      const prdListFiltered = prdList.content
        .filter((item) => item.prdImgFlpth !== null)
        .map((item) => {
          if (item.notitlePdCmPrice === null) {
            item.notitlePdCmPrice = 0;
          }
          return item;
        });
      console.log(prdListFiltered);
      setProductList(prdListFiltered);
      setMapLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (mapLoading) {
    return (
      <Container>
        <Row>
          <Col>
            {mapLoading && (
              <div style={{ height: "300px" }}>
                <Loading />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <main>
      <MainMap productList={productList} />
      <Container>
        <div style={{ marginTop: "20px" }}>
          <Slider {...settings}>
            {
              productList
                .slice(50, 50 + 10)
                .map((product, index) => (
                  <div key={index}
                    style={{ flex: "0 0 200px", marginRight: "20px", border: "3px solid black" }}>
                    <Card onClick={() => handleClick(product)} style={{ border: 0 }}>
                      {isLoading[index] && (
                        <div style={{ height: "150px" }}>
                          <Loading />
                        </div>
                      )}
                      <Card.Img
                        onLoad={() => handleImageLoad(index)}
                        style={{
                          width: "150px",
                          margin: "0 auto",
                          objectFit: "cover",
                          display: isLoading[index] ? "none" : "block",
                        }}
                        src={
                          product?.prdImgFlpth
                            ? `https://cloud.1472.ai:18443/images/cloud/uploads/${product?.prdImgFlpth}`
                            : product?.extrlImgUrl
                        }
                      />
                      <Card.Body style={{ textAlign: "left" }}>
                        <Card.Text style={{ fontSize: "15px", marginBottom: "0.4rem", marginTop: "1rem" }}>
                          <span className="badge badge-primary" style={{ backgroundColor: "#F39802", marginRight: "0.5rem" }}>{product?.cmpnyNm}</span>
                          <span className="badge badge-primary" style={{ border: "1px solid #F39802", color: "#F39802", backgroundColor: "white" }}>{product.areaNm}</span>
                          {
                            product.salesStatus === "S03" ?
                              (<span className="badge badge-primary" style={{ backgroundColor: "red", marginLeft: "0.5rem" }}>품절</span>) : (null)
                          }
                        </Card.Text>
                        <Card.Title style={{ fontSize: "17px", color: product.salesStatus === "S03" ? "#ddd" : "black" }}>
                          {product?.prductNm.length > 20
                            ? product?.prductNm.substring(0, 20) + ".."
                            : product?.prductNm || ""}
                        </Card.Title>
                        <Card.Text style={{ fontSize: "18px", fontWeight: 700, color: product.salesStatus === "S03" ? "#ddd" : "black" }}>
                          {product?.notitlePdCmPrice.toLocaleString()}원
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))
            }
          </Slider>
        </div>


        <FeaturedProducts productList={productList} />

        <h2>신상품</h2>
        <Row style={{ marginBottom: "7rem" }}>
          {productList
            .slice(-8)
            .map((product, index) => (
              <Col key={index} xs={6} md={3} className="mb-5">
                <Card onClick={() => handleClick(product)} style={{ height: "80%", border: 0 }}>
                  {isLoading[index] && (
                    <div
                      style={{
                        height: "150px",
                      }}
                    >
                      <Loading />
                    </div>
                  )}
                  <Card.Img
                    onLoad={() => handleImageLoad(index)}
                    style={{
                      objectFit: "contain",
                      display: isLoading[index] ? "none" : "block",
                      height: "90%"
                    }}
                    src={
                      product?.prdImgFlpth
                        ? `https://cloud.1472.ai:18443/images/cloud/uploads/${product?.prdImgFlpth}`
                        : product?.extrlImgUrl
                    }
                  />
                  <Card.Body style={{ textAlign: "left", padding: 0 }}>
                    <Card.Text style={{ fontSize: "15px", marginBottom: "0.4rem", marginTop: "1rem" }}>
                      <span className="badge badge-primary" style={{ backgroundColor: "#F39802", marginRight: "0.5rem" }}>{product?.cmpnyNm}</span>
                      <span className="badge badge-primary" style={{ border: "1px solid #F39802", color: "#F39802", backgroundColor: "white" }}>{product.areaNm}</span>
                      {
                        product.salesStatus === "S03" ?
                          (<span className="badge badge-primary" style={{ backgroundColor: "red", marginLeft: "0.5rem" }}>품절</span>) : (null)
                      }
                    </Card.Text>
                    <Card.Title style={{ fontSize: "17px", color: product.salesStatus === "S03" ? "#ddd" : "black" }}>
                      {product?.prductNm.length > 16
                        ? product?.prductNm.substring(0, 16) + ".."
                        : product?.prductNm || ""}
                    </Card.Title>
                    <Card.Text style={{ fontSize: "20px", fontWeight: 700, color: product.salesStatus === "S03" ? "#ddd" : "black" }}>
                      {product?.notitlePdCmPrice.toLocaleString()}원
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </main>
  );
}

export default Main;
