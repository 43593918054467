import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
} from "react-bootstrap";
import { FaMinus, FaPlus } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { errorToast, successToast } from "../common/Toast";
import dayjs from 'dayjs'
import { useTitle } from "../common/UseTitle";

const Cart = () => {
  const isLoggedIn = useSelector(state => state.isLoggedIn);
  const userInfo = useSelector(state => state.user) ?? [];
  const [items, setItems] = useState([]);
  const [nearCmpnyItems, setNearCmpnyItems] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [checked, setChecked] = useState([]);
  const navigate = useNavigate();
  const nearCmpny = JSON.parse(localStorage.getItem('nearCm')) ?? "";
  const changeTitle = useTitle();

  useEffect(() => {
    changeTitle("e층창고");
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      handleGetCart();
      nearItem();
    }
  }, []);

  const nearItem = async () => {
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          echanggoYn: "Y",
          cmpnyNo: nearCmpny.cmpnyNo,
          offset: 0,
          pageNumber: 0,
          pageSize: 100,
          paged: true,
        }),
      })
      const data = await response.json();
      const dummy = data.content;
      const selectedIndexes = [];
      if (dummy.length > 3) {
        while (selectedIndexes.length < 3) {
          const randomIndex = Math.floor(Math.random() * data.content.length);
          if (!selectedIndexes.includes(randomIndex)) {
            selectedIndexes.push(randomIndex);
          }
        }
        const newArray = selectedIndexes.map(index => data.content[index]);
        setNearCmpnyItems(newArray);
      } else {
        setNearCmpnyItems(data.content);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleGetCart = () => {
    let prdNoList = [];
    fetch("https://erp.1472.ai:28443/api/v1/basketInqry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mberNo: userInfo.mberNo,
        gubun: 'E',
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: false
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        const newList = data.content;
        setItems(data.content);

        newList.map((item) => {
          const prdNo = item.prdNo.split("_")[0];
          if (item.prdNo.includes("_")) {
            if (!prdNoList.includes(prdNo)) {
              prdNoList.push(prdNo);
            }
          }
        })

        // 장바구니에서 옵션이 있는 상품 옵션조회
        prdNoList.map(async (item) => {
          const response = await fetch("https://cloud.1472.ai:18443/api/v1/prdOptNew", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              prdNo: item,
              delYn: "N",
              offset: 0,
              pageNumber: 0,
              pageSize: 10,
              paged: true
            })
          });
          const data = await response.json();
          console.log(data.content);
          setOptionList((list) => [...list, data.content]);
        })
      })
      .catch((error) => console.error(error));
  };

  const totalPrice = items.reduce((acc, item) => {
    if (checked.find((checkedItem) => checkedItem.bsktRegNo === item.bsktRegNo)) {
      return acc + item.prdPrice * item.qty;
    } else {
      return acc;
    }
  }, 0);

  const countCmpny = () => {
    let cmpny = [];
    items.map((item) => {
      if (checked.find((checkedItem) => checkedItem.bsktRegNo === item.bsktRegNo)) {
        if (!cmpny.includes(item.bcncCmpnyNo)) {
          cmpny.push(item.bcncCmpnyNo);
        }
      }
    })

    return cmpny.length;
  }

  const handleQtyChange = (id, value) => {
    let newQty = 0;
    items.map((item) => {
      if (item.bsktRegNo === id) {
        newQty = item.qty + value;
        if (newQty < 1) {
          newQty = 1;
        }
      }
    });
    fetch("https://erp.1472.ai:28443/api/v1/baksetUpdate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bsktRegNo: id,
        delYn: "N",
        qty: newQty
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        handleGetCart();
      })
      .catch((error) => console.error(error));
  };

  const handleRemoveItem = (id) => {
    fetch("https://erp.1472.ai:28443/api/v1/baksetUpdate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bsktRegNo: id,
        delYn: "Y"
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        handleGetCart();
        const newArray = checked.filter((item) => item.bsktRegNo !== id);
        setChecked(newArray);
        successToast("상품을 삭제했습니다.")
      })
      .catch((error) => console.error(error));
  };

  // 체크항목 일괄삭제
  const checkRemoveItem = () => {
    checked.map((item) => {
      fetch("https://erp.1472.ai:28443/api/v1/baksetUpdate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bsktRegNo: item.bsktRegNo,
          delYn: "Y"
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          handleGetCart();
          document.getElementById('allCheck').checked = false;
        })
        .catch((error) => console.error(error));
    })
    const newArray = [];
    setChecked(newArray);
    successToast("상품을 삭제했습니다.")
  };

  // 상품연동 api
  const stockSply = async (item) => {
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/stockSply", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          stockSplyList: [{
            cmpnyNo: userInfo.cmpnyNo,
            wrhousNo: 0,
            wrhousScNo: 0,
            qy: 0,
            prdNo: item.prdNo,
            splySe: "SIO002",
            notitlePdCmPrice: item.prdPrice,
            salesStatus: "S01"
          }]
        }),
      })
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  }

  // 주문서 api
  const insertOrd = async () => {
    const bcncTelno = userInfo.moblphonNo.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    const nowTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const ordId = dayjs().format("YYYYMMDDHHmmssSSS") + "-" + userInfo.mberNo;

    let bcncCmpnyNo = [];
    checked.map((item) => {
      if (!bcncCmpnyNo.find((i) => i.bcncCmpnyNo === item.bcncCmpnyNo)) {
        bcncCmpnyNo.push(item);
      }
    })

    // 상품연동 api
    checked.map((item) => {
      stockSply(item);
    })

    let orderNum = [];
    await Promise.all(bcncCmpnyNo.map(async (item) => {
      const orderName = [];
      let newData = {};
      let dtlList = [];
      let demandTot = 0;
      let totAmount = "";
      for (let i = 0; i < items.length; i++) {
        if (item.bcncCmpnyNo === items[i].bcncCmpnyNo) {
          orderName.push(items[i].prdNm);
          demandTot = demandTot + items[i].prdPrice * items[i].qty;
          totAmount = items[i].prdPrice * items[i].qty;
          if (checked.some((checkedItem) => checkedItem.bsktRegNo === items[i].bsktRegNo)) {
            newData = {
              prdNo: items[i].prdNo,
              prductNm: items[i].prdNm,
              setNo: 0,
              totAmount: totAmount,
              prductCl: "",
              qy: items[i].qty,
              cost: items[i].prdPrice,
              m: 0,
              n: 0,
              supplyAmt: 0,
              tax: 0,
              ordQy: 0,
              bomSetSe: "1",
              asPd: 0,
              prmpc: 0,
              prmpcSm: 0,
              profitAmt: 0,
              selngEntrpsNo: 0,
              socNo: 0,
              dlivMatter: "",
              vendnm: "",
              delYn: "N",
              repImg: items[i].imgUrl,
              selngDataNo: 0,
              dlvPrpNo: "",
              ordReqNo: 0,
              setYn: "N",
            }
            dtlList.push(newData);
          }
        }
      }
      let ordNm = "";
      if (orderName.length === 1) {
        ordNm = orderName[0]
      } else {
        ordNm = orderName[0] + " 외 " + (orderName.length - 1) + "건";
      }
      console.log(ordNm);

      const response = await fetch("https://erp.1472.ai:28443/api/v1/ord", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ordVo: {
            dlvySptAdres: "",
            // dlvySptNm: '',
            dtlList: dtlList,
            ordCmpnyNo: userInfo.cmpnyNo, // 주문회사번호
            ordMberNo: userInfo.mberNo, // 주문회원번호
            wrter: userInfo.mberNo, // 작성자
            ordSttusCl: "ORDS02", // 주문상태분류
            progrsSttus: "OWS011", // 진행상태
            writeDt: nowTime, // 작성일시
            ordDt: nowTime, // 주문일시
            ordId: ordId, // 주문아이디
            dedtDe: nowTime, // 납기일자
            bcncNo: item.bcncCmpnyNo, // 거래처번호
            bcncNm: item.bcncCmpnyNm, // 거래처명
            bcncTelno: bcncTelno, // 거래처전화번호
            sptNm: "", // 현장명 넣기
            sptAdres: "", // 현장주소 넣기
            dlvyMth: "택배", // 배송방법
            dlvyCt: "선불", // 배송비
            rcpnt: userInfo.name, // 수령자
            dept: "없음", // 부서
            vatCl: "VATS03", // 부가세분류
            demandTot: demandTot, // 출고액합계
            trnsprtDvyfgAm: 3000, // 운송,납품액
            bndePrductCl: "전체", // 일괄제품분류
            bndeMargin: 0, // 일괄마진
            bndeNego: 0, // 일괄네고
            sumry: ordNm, // 적요
            ordCours: "OCR002", // 주문경로
            ordCrcmstncs: "e층창고", // 주문경위
            buyNo: 0, // 매입관리번호
            selngNo: 0, // 매출관리번호
            faxYn: "N", // 팩스여부
            prtYn: "N", // 출력여부
            dlivyYn: "N", // 출고여부
            bilclctYn: "N", // 수금여부
            delYn: "N", // 삭제여부
            schRegYn: "N" // 일정등록여부
          }
        })
      })
      const data = await response.json();
      console.log(data)
      orderNum.push(data.message);
    }))
    orderNum.sort((a, b) => a - b);

    const result = delBskt();
    if (result) {
      navigate("/order", {
        state: { orderNum: orderNum },
      });
    } else {
      errorToast("다시 시도해주세요.")
    }
  }

  // 상품 이미지 클릭시 이동
  const handleClick = async (item) => {
    let prdNo;
    let cmpnyNo;
    let product;
    if (typeof item.prdNo === 'number') {
      prdNo = item.prdNo;
    } else if (typeof item.prdNo === 'string') {
      prdNo = item.prdNo.split('_')[0];
    }
    if (!item.bcncCmpnyNo) {
      cmpnyNo = item.cmpnyNo
    } else {
      cmpnyNo = item.bcncCmpnyNo
    }
    try {

      const response = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prdNo: prdNo,
          cmpnyNo: cmpnyNo,
          echanggoYn: "Y",
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        })
      })
      const data = await response.json();
      console.log(data);
      product = data.content[0];
    } catch (error) {
      console.error(error);
    }
    navigate("/product-detail", {
      state: { product: product }
    })
  }

  // 주문후 장바구니 비우기
  const delBskt = async () => {
    try {
      await Promise.all(checked.map(async (item) => {
        const response = await fetch("https://erp.1472.ai:28443/api/v1/baksetUpdate", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            bsktRegNo: item.bsktRegNo,
            delYn: "Y"
          })
        });
        const data = await response.json();
        console.log(data);
      }));
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  // 옵션이름 렌더링
  const renderOption = (item) => {
    if (item.prdNo.includes("_")) {
      const optNo = item.prdNo.split("_")[1];
      let optNm = "";
      optionList.map((arr) => {
        arr.map((obj) => {
          if (obj.optNo == optNo) {
            optNm = "옵션 : " + obj.optNm
          }
        })
      })
      return optNm;
    }
  }

  //체크박스
  const handleCheck = (bskt, e) => {
    let newArray = [...checked];
    if (e.target.checked) {
      newArray.push(bskt);
    } else {
      newArray = newArray.filter((item) => item.bsktRegNo !== bskt.bsktRegNo);
      document.getElementById('allCheck').checked = false;
    }
    setChecked(newArray);
  };

  const allCheck = (checked) => {
    const newArray = checked ? [...items] : [];
    setChecked(newArray);
  };

  return (
    <Container style={{ marginTop: "10em", marginBottom: '10em' }}>
      <Row className="my-4">
        <Col>
          <h2>장바구니</h2>
          {
            items.length > 0 ? (
              <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "800px", margin: "0 auto" }}>
                <div style={{ display: "flex" }}>
                  <input type="checkbox"
                    id="allCheck"
                    style={{ border: "2px solid black" }}
                    onClick={(e) => {
                      allCheck(e.target.checked);
                    }}
                  />
                  <label htmlFor="allCheck">전체선택</label>
                </div>
                <Button
                  style={{ backgroundColor: "#F39802", border: 0 }}
                  onClick={() => {
                    if (checked.length > 0) {
                      checkRemoveItem();
                    } else {
                      errorToast("한개 이상의 항목을 체크해주세요.")
                    }
                  }}>선택 삭제</Button>
              </div>
            ) : (
              <></>
            )
          }
        </Col>
      </Row>
      {items.length > 0 ? (
        <Card style={{ maxWidth: "800px", margin: "0 auto" }}>
          <Card.Body>
            {
              items.map((item, i) => (
                <Card className="mb-4" key={item.bsktRegNo}>
                  <input style={{ margin: "5px", marginLeft: "20px", marginBottom: 0, border: "2px solid black" }}
                    type="checkbox"
                    checked={checked.find((obj) => obj.bsktRegNo === item.bsktRegNo) ? true : false}
                    name="bskt"
                    onChange={(e) => {
                      handleCheck(item, e)
                    }}
                  />
                  <Card.Body style={{ paddingTop: "0" }}>
                    <ul className="list-group">
                      <div style={{ display: "flex", margin: "6px 0", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img onClick={() => {
                            handleClick(item);
                          }} style={{ width: "90px", cursor: "pointer" }} src={`https://cloud.1472.ai:18443/images/cloud/uploads/${item.imgUrl}`} />
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "20px", maxWidth: "200px" }}>
                            <span style={{ color: "rgb(153, 153, 153)" }}>{item.bcncCmpnyNm}</span>
                            <span onClick={() => {
                              handleClick(item);
                            }} style={{ fontWeight: 500, textAlign: "left", cursor: "pointer" }}>{item.prdNm}</span>
                            <span style={{ fontWeight: 500 }}>{renderOption(item)}</span><span style={{ fontWeight: 500 }}>{(item.prdPrice).toLocaleString()}원</span>
                          </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "end", justifyContent: "space-between", width: "250px", lineHeight: "32px" }}>
                          <p style={{ whiteSpace: "nowrap", margin: 0 }}>
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              className="me-2"
                              onClick={() => handleQtyChange(item.bsktRegNo, -1)}
                            >
                              <FaMinus />
                            </Button>
                            <span className="mx-2">{item.qty}</span>
                            <Button
                              variant="outline-secondary"
                              size="sm"
                              className="ms-2"
                              onClick={() => handleQtyChange(item.bsktRegNo, 1)}
                            >
                              <FaPlus />
                            </Button>
                          </p>
                          <p style={{ margin: 0, verticalAlign: "middle" }}>
                            <strong>
                              {(item.prdPrice * item.qty).toLocaleString()}원
                            </strong>
                          </p>
                          <Button
                            style={{ padding: "3px", marginBottom: "0" }}
                            variant="danger"
                            onClick={() => handleRemoveItem(item.bsktRegNo)}
                          >
                            <AiOutlineDelete />
                          </Button>
                        </div>
                      </div>
                    </ul>
                  </Card.Body>
                </Card>
              ))
            }
          </Card.Body>
        </Card>
      ) : (
        <Row>
          <Col>
            <p>장바구니가 비어 있습니다.</p>
          </Col>
        </Row>
      )
      }
      <Row>
        <Col>
          <div className="my-4">
            <h5>주문 요약</h5>
            <hr />
            <div className="d-flex justify-content-between">
              <h6>상품 금액</h6>
              <h6>{totalPrice.toLocaleString()}원</h6>
            </div>
            <div className="d-flex justify-content-between">
              <h6>배송비</h6>
              <h6>{(countCmpny() * 3000).toLocaleString()}원</h6>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <h5>총 결제 금액</h5>
              <h5>{(totalPrice + (countCmpny() * 3000)).toLocaleString()}원</h5>
            </div>
          </div>
        </Col>
      </Row>
      <Row style={{ marginBottom: '10em' }}>
        <Col>
          <div className="d-flex justify-content-end">
            <Link to="/">
              <Button
                variant="light"
                size="sm"
                className="mr-3"
                style={{ color: "#F39802", border: "1px solid #F39802" }}>
                쇼핑 계속하기
              </Button>
            </Link>
            <Button
              variant="primary"
              size="sm"
              style={{ backgroundColor: "#F39802", border: 0 }}
              onClick={() => {
                if (checked.length > 0) {
                  insertOrd();
                } else {
                  errorToast("한개 이상의 항목을 체크해주세요.")
                }
              }}
            >
              주문하기
            </Button>
          </div>
        </Col>
      </Row>
      {
        nearCmpnyItems.length !== 0 ? (
          <>
            <Row className="my-5" style={{ marginTop: '3em' }}>
              <Col>
                <h2>내 주변 상품</h2>
              </Col>
            </Row>
            <Row className="my-5">
              {
                nearCmpnyItems.map((item) => (
                  <Col md={4} key={item.prdNo}>
                    <Card>
                      <Card.Img
                        variant="top"
                        src={`https://cloud.1472.ai:18443/images/cloud/uploads/${item.prdImgFlpth}`}
                      />
                      <Card.Body>
                        <Card.Text>{item.prductNm}</Card.Text>
                        <Card.Text>
                          {(item.notitlePdCmPrice).toLocaleString()}원
                        </Card.Text>
                        <Button
                          variant="primary"
                          style={{ backgroundColor: "#F39802", border: 0 }}
                          onClick={() => {
                            handleClick(item);
                          }}
                        >보러가기</Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              }
            </Row>
          </>
        ) : (null)
      }

    </Container >
  );
};

export default Cart;
