import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import ProductDetailImage from "./ProductDetailImage";
import ProductDetailInfo from "./ProductDetailInfo";
import ProductDetailContents from "./ProductDetailContents";
import ProductMap from "../map/ProductMap";
import { useTitle } from "../common/UseTitle";

function ProductDetail() {
  const location = useLocation();
  const product = location.state?.product || "";
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [productDetail, setProductList] = useState("");
  const [emptyPrd, setEmptyPrd] = useState(false);



  const changeTitle = useTitle();

  useEffect(() => {
    if (!product) {
      setEmptyPrd(true);
      return;
    }
    let title = "";
    if (product.brand !== "") {
      title = `${product.brand} ${product.prductNm}`
    } else {
      title = product.prductNm;
    }
    changeTitle(title);
    getProductDetail();
  }, [])

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
  };

  const getProductDetail = async () => {
    try {
      const response = await fetch(
        `https://cloud.1472.ai:18443/api/v2/prdSynth/${product.prdNo}`,
        {
          method: "POST",
          headers: {
            Accept: "*/*",
          },
        }
      );

      const prdDetail = await response.json();
      setProductList(prdDetail);
    } catch (error) {
      console.error(error);
    }
  };

  if (emptyPrd) {
    return (
      <main>
        <Container style={{ marginTop: "8rem" }}>
          지금은 판매하지 않는 상품입니다.
        </Container>
      </main>
    )
  }

  return (
    <main>
      <Container style={{ marginTop: "8rem" }}>
        <Row>
          <Col lg={6}>
            <ProductDetailImage
              currentImageIndex={currentImageIndex}
              onImageClick={handleImageClick}
              prdDetail={productDetail}
            />
          </Col>
          <Col lg={6}>
            <ProductDetailInfo prdDetail={product} />
          </Col>
        </Row>
      </Container>
      <ProductMap key="productmap1" prd={product} />
      <Container style={{ marginBottom: "7rem" }}>
        <ProductDetailContents prdDetail={productDetail} />
      </Container>
    </main>
  );
}

export default ProductDetail;
