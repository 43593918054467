import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  bottom: 0;
  width: 100%;
  height: 100%;
  line-height: 40px;
  background-color: #1C1C1C;
  color: #999999;

  @media (max-width: 991px) {
    display: none;
  }
`;

function Footer() {
  return (
    <StyledFooter>
      <footer id="footer">
        <Container>
          <Row>
            <Col md={3} style={{ marginTop: '3em' }}>
              <h4>링크</h4>
              <ul className="list-unstyled">
                <li>
                  <a href="/">홈</a>
                </li>
              </ul>
            </Col>
            <Col md={3} style={{ marginTop: '3em' }}>
              <h4>정보</h4>
              <ul className="list-unstyled">
                <li>
                  <a href="/terms-of-service">이용약관</a>
                </li>
                <li>
                  <a href="/privacy-policy">개인정보 처리방침</a>
                </li>
              </ul>
            </Col>
            <Col md={6} style={{ marginTop: '3em' }}>
              <h4>연락처</h4>
              <p>
                대전 광역시 서구 둔산남로 176 2층 노타이틀<br />
                전화: 010-4515-4243<br />
                이메일: notitle@1472.ai
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={6} style={{ marginBottom: '3em' }}>
              <p>&copy; 2023 Notitle Company, All rights reserved.</p>
            </Col>
            {/* <Col md={6} className="text-end">
              <p>Designed by <a href="https://example.com">ogto</a></p>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </StyledFooter>
  );
}

export default Footer;