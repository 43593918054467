import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Form, Table, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../common/Loading';
import { useTitle } from '../common/UseTitle';

const Portfolio = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(false);
  const changeTitle = useTitle();

  useEffect(() => {
    getPortfolio();
    changeTitle("e층창고");
  }, [])

  useEffect(() => {
    // scroll event listener 등록
    window.addEventListener("scroll", handleScroll);
    return () => {
      // scroll event listener 해제
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    if (page === endPage) {
      return;
    }
    if (scrollTop + clientHeight >= scrollHeight && fetching === false) {
      // 페이지 끝에 도달하면 추가 데이터를 받아온다
      moreData(page);
      setPage(page + 1);
    }
  };

  const handleMouseEnter = (i) => {
    setIsHovered((prev) => {
      const update = [...prev];
      update[i] = true;
      return update;
    })
  };

  const handleMouseLeave = (i) => {
    setIsHovered((prev) => {
      const update = [...prev];
      update[i] = false;
      return update;
    });
  };

  const getPortfolio = async () => {
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/portMgmt/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          useYn: "Y",
          offset: 0,
          pageNumber: 0,
          pageSize: 12,
          paged: true
        }),
      });
      const data = await response.json();
      const dummy = data.content
      const portfolioFiltered = dummy.filter((item) => item.imgUrl && item.imgUrl.includes("images/cloud/"));
      setPortfolio(portfolioFiltered);
      setEndPage(data.totalPages);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const moreData = async (i) => {
    setFetching(true);
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/portMgmt/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          useYn: "Y",
          offset: i * 12,
          pageNumber: i,
          pageSize: 12,
          paged: true
        }),
      });
      const data = await response.json();
      const dummy = data.content
      const portfolioFiltered = dummy.filter((item) => item.imgUrl.includes("images/cloud/"));
      setPortfolio((prevPortfolioList) => [...prevPortfolioList, ...portfolioFiltered]);
    } catch (error) {
      console.error(error);
    }
    setFetching(false);
  }

  if (isLoading) {
    return (
      <Container>
        <Row>
          <Col>
            {isLoading && (
              <div
                style={{
                  height: "300px",
                }}
              >
                <Loading />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container className="py-5" style={{ marginTop: "5em", marginBottom: '5em' }}>
      <Row>
        {
          portfolio.map((item, i) => (
            <Col key={item.portNo} xs={6} md={4}>
              <div style={{
                transform: isHovered[i] ? 'scale(1.05)' : 'scale(1)',
                filter: isHovered[i] ? 'brightness(0.5)' : 'brightness(1)',
                transition: 'transform 0.3s, filter 0.3s',
                cursor: 'pointer',
                marginBottom: '1rem'
              }}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={() => handleMouseLeave(i)}
                onClick={() => {
                  navigate("/portfolio-detail", {
                    state: {
                      portfolio: item,
                      cmpnyNo: item.cmpnyNo
                    }
                  })
                }}
              >
                <Card>
                  <Card.Img src={`https://cloud.1472.ai:18443${item.imgUrl}`} style={{ objectFit: "contain", height: "20vh" }} />
                </Card>
                <div style={{ textAlign: "left" }}>
                  <span className="badge badge-primary" style={{ backgroundColor: "#F39802" }}>{item.cmpnyNm}</span>
                  <div style={{ fontWeight: "600" }}>{item.title}</div>
                  {/* <div style={{ fontSize: "0.9rem" }}>{item.title}</div> */}
                </div>
              </div>
            </Col>
          ))
        }
      </Row>
      {
        fetching ? (<div
          style={{
            height: "300px",
          }}
        >
          <Loading />
        </div>) : null
      }
    </Container>
  );
};

export default Portfolio;