import React, { useEffect } from 'react';
import { Container } from "react-bootstrap";
import { useSearchParams } from 'react-router-dom';

function Fail() {
  const [searchParams] = useSearchParams()
  console.log(searchParams.get("message"))
  console.log(searchParams.get("code"))
  console.log(searchParams.get("orderId"))

  // useEffect(() => {
  //   getPayments();
  // }, []);

  // const getPayments = () => {
  //   fetch("https://api.tosspayments.com/v1/payments/confirm", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       paymentKey: searchParams.get("paymentKey"),
  //       orderId: searchParams.get("orderId"),
  //       amount: searchParams.get("amount")
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data)
  //     })

  // }
  return (
    <Container
      className="py-5"
      style={{ marginTop: "5em", marginBottom: "5em" }}
    >
      <div>
        결제 실패 페이지
      </div>
    </Container>
  );
};

export default Fail;