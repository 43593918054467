import React from 'react';
import ReactDOM from 'react-dom';
import { Card } from 'react-bootstrap';
import './modal.css';

const Modal = ({ isOpen, onClose, title, children, name }) => {
  if (!isOpen) return null;

  const handleClose = () => {
    onClose();
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return ReactDOM.createPortal(
    <div className="modal">
      <div className="backdrop" onClick={handleClose}></div>
      <div className="content" onClick={handleContentClick}>
        <Card bg="light" border="dark" text="dark" className={name}>
          <Card.Header>{title}</Card.Header>
          <Card.Body style={{ textAlign: 'center' }}>{children}</Card.Body>
        </Card>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

export default Modal;