import React from 'react';

const Terms1 = () => {
  return (
    <div>
      제 1 조 (목적)<br />
      이 약관(이하 "약관"이라고 합니다)은 주식회사 노타이틀(이하 "회사"라고 합니다)가 제공하는 노타이틀 서비스의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.<br />
      <br />
      제 2 조 (정의)<br />
      "약관"에서 사용하는 용어의 정의는 아래와 같습니다.<br />
      <br />
      ① "노타이틀 서비스"(이하"서비스"라고 합니다)라 함은 "회원"이 회원 본인의 모바일웹 홈페이지를 제작 및 운영 할 수 있도록 “회사”가 제공하는 제작 툴(에디터), 도메인, 서버공간 및 홈페이지 관리시스템 등을 의미합니다.<br />
      ② "회원" 이라 함은 회사의 "서비스"를 이용하기 위해 "약관"에 따라 "회사"와 이용계약을 체결하고 "회사"가 제공하는 "서비스"를 이용하는 고객을 말합니다.<br />
      ③ "노타이틀 홈" 이라 함은 "회원"이 "서비스"의 이용과 관련된 신청, 관리 등을 할 수 있도록 "회사"가 제공하는 웹사이트를 의미합니다.<br />
      ④ "계정"이라 함은 "회원"이 "서비스"를 이용할 수 있도록 "회사"가 "회원"에게 부여하는 권한단위를 의미합니다.<br />
      ⑤ "아이디(ID)"라 함은 "회원"을 식별하고 "회원"이 "서비스"를 이용하도록 하기 위해 "회원"이 정하고 "회사"가 승인하는 문자와 숫자의 조합을 의미합니다.<br />
      ⑥ "비밀번호" 이라 함은 고객이 이미 부여된 "아이디(ID)"로 식별되는 "회원"임을 확인하고 "회원"의 "서비스" 이용 등과 관련된 비밀을 보호하기 위해 "회원"이 정한 문자 또는 숫자의 조합을 의미합니다.<br />
      ⑦ "노타이틀 홈페이지"(이하 "홈페이지"라고 합니다)이라 함은 "회원"이 "서비스"를 이용하여 제작, 운영하는 회원 자신의 모바일웹 홈페이지를 의미합니다.<br />
      ⑧ "게시물" 이라 함은 "회원"이 "홈페이지" 상에 게시한 부호 · 문자 · 이미지 · 음성 · 음향 · 동영상 등의 정보 및 링크, “홈페이지”에 업로드한 각종 파일 등을 의미합니다.<br />
      ⑨ "약관"에서 사용하는 용어 중 본 조 제1항 내지 제8항에서 하지 아니한 것은 관련 법령 및 일반적인 상관례에 따릅니다.<br />
      제 3 조 (약관의 게시와 개정)<br />
      ① "회사"는 "약관"의 내용을 "회원"이 쉽게 알 수 있도록 "노타이틀 홈" 초기 화면 또는 연결 화면을 통하여 게시합니다.<br />
      ② "회사"는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 "약관"을 개정할 수 있습니다.<br />
      ③ "회사"는 "약관"을 개정할 경우 개정내용과 적용일자를 명시하여 제12조에 따른 방법으로 적용일자 7일 전부터 적용일자 전일까지 통지합니다. 다만, "회원"에게 불리하게 개정되는 경우에는 적용일자 30일 전부터 적용일자까지 공지하고, “회원”이 기재한 전자우편, 전자쪽지, 로그인 시 노출되는 팝업 창 등의 전자적 수단을 통해 별도로 통지합니다.<br />
      ④ "회사"가 전항에 따라 "약관"의 개정을 공지하면서 "회원"에게 적용일자 전일까지 이의 제기 등 거부의 의사표시를 하지 않으면 동의의 의사표시가 표명된 것으로 본다는 뜻을 명확하게 고지했거나 제12조에 따른 방법으로 통지하였음에도 "회원"이 명시적으로 거부의 의사표시를 하지 않은 경우 "회원"이 개정되는 "약관"에 동의한 것으로 봅니다.<br />
      ⑤ "회원"은 "약관"의 개정에 동의하지 않을 경우 적용일자 전일까지 "회사"에 거부의사를 표시하고 "회원" 탈퇴를 할 수 있습니다.<br />
      제 4 조 (회원 가입)<br />
      ① "회원" 가입은 "서비스"를 이용하고자 하는 자(이하 "가입신청자"라고 합니다)가 노타이틀 서비스 아이디로 로그인 한 후 본 "약관"의 내용에 동의를 하면 가입신청이 됩니다.<br />
      ② "회사"는 "가입신청자"의 가입신청에 대하여 "서비스" 이용을 승낙함을 원칙으로 합니다. 다만, "회사"는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나, 회원가입 이후에라도 다음의 각 호의 사유가 확인될 경우에는 제20조에 따라 "회원" 자격 박탈 조치를 할 수 있습니다.<br />
      1. "가입신청자"가 "약관"에 의하여 이전에 "회원"자격을 상실한 적이 있는 경우<br />
      2. "가입신청자"가 "약관"에 의하여 "서비스" 이용정지를 받고 있던 중에 "회원" 탈퇴를 한 적이 있는 경우<br />
      3. 실명이 아닌 명의 또는 타인의 명의를 기재한 경우<br />
      4. 허위의 정보를 기재 또는 제공하거나, "회사"가 제시하는 내용을 기재하지 않은 경우<br />
      5. "가입신청자"가 14세 미만의 아동인 경우<br />
      6. "가입신청자"의 귀책사유로 인하여 승낙이 불가능하거나 기타 "약관"에서 규정한 제반 사항을 위반 하여 신청하는 경우<br />
      ③ "회원" 가입의 성립 시기는 "회사"가 가입완료를 신청절차 상에서 표시하거나, 제12조에 따른 통지가 "가입신청자"에게 도달한 시점으로 합니다.<br />
      제 5 조 (회원정보 등의 변경)<br />
      ① "회원"은 회원정보 관리화면을 통하여 언제든지 본인의 회원정보를 열람할 수 있으며, "노타이틀 홈"또는 노타이틀 고객센터를 통해 수정을 신청할 수 있습니다.<br />
      ② "회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우, 회원정보 관리화면을 통하여 수정을 하거나 "회사"에 대하여 그 변경사항을 알려야 합니다.<br />
      ③ "회원"이 제 2항을 위반하여 변경사항을 "회사"에 알리지 않아 발생한 불이익에 대해서 "회사"는 책임지지 않습니다.<br />
      제 6 조 (회원의 계정 관리의무)<br />
      ① "회원"의 "아이디(ID)"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, "회원"은 이를 제3자가 이용하도록 하여서는 안 됩니다.<br />
      ② "회원"은 자신의 "아이디" 및 "비밀번호"가 도용되었거나 제3자에 의해 사용되고 있음을 인지한 경우 이를 즉시 "회사"에 통지하고 "회사"의 안내에 따라야 합니다.<br />
      ③ "회사"는 "회원"의 "아이디" 등 개인정보의 유출 우려가 있는 경우, 해당 계정의 이용을 제한할 수 있으며, 이러한 경우 제12조에 따른 방법으로 "회원"에게 통지합니다.<br />
      ④ "회원"이 제1항의 의무를 위반하거나 제2항에 따라 "회사"에 그 사실을 통지하지 않거나, 통지한 경우라도 "회사"의 안내에 따르지 않아 발생한 불이익에 대해서 "회사"는 책임지지 않습니다.<br />
      제 7 조 (회사의 개인정보 보호의무)<br />
      "회사"는 "정보통신망 이용촉진 및 정보보호 등에 관한 법률", "개인정보보호법" 등 관련 법령이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 이용에 대해서는 관련 법령 및 "회사"의 개인정보처리방침이 적용됩니다. 다만, "회사"의 공식 사이트 이외의 링크된 사이트나 “회원”이 관리하는 “홈페이지” (노타이틀 서비스 내 회원 홈페이지)에서는 "회사"의 개인정보처리방침이 적용되지 않습니다.<br />
      개인정보보호정책<br />
      <br />
      제 8 조 (서비스 이용계약 체결)<br />
      ① "서비스" 이용계약은 "회원"이 "회사"가 정한 절차에 따라 "서비스" 이용을 신청하고 "회사"가 이를 승낙함으로써 체결되며, "회사"는 구체적인 신청절차 및 승낙조건 등에 관하여 "노타이틀 홈"을 통해 안내할 수 있습니다.<br />
      ② "회사"는 "서비스"관련 설비의 여유가 없거나, 기술상 또는 업무상 장애가 있는 경우에는 승낙을 유보할 수 있습니다.<br />
      ③ 제2항에 따라 "서비스" 이용의 승낙을 하지 아니하거나 유보한 경우, "회사"는 원칙적으로 이를 제12조에 따른 방법으로 "회원"에게 통지합니다.<br />
      ④ 이용계약의 성립 시기는 "회사"가 승낙조건 등을 확인하여 계약체결을 "서비스" 이용계약 체결절차 상에서 표시하거나 제12조에 따른 방법으로 통지하여 "회원"에게 도달한 시점으로 합니다.<br />
      ⑤ "회사"가 본 조에 따라 "서비스" 이용 신청을 승낙한 것이 "회원"이 생성한 "홈페이지" 또는 해당 "홈페이지"의 "게시물"이 관련 법령을 위반하지 않는다거나 "약관"이나 서비스 운영정책, 이용안내 등에 적합함을 보증하거나 보장하는 것은 아니며, 승낙 이후에라도 "홈페이지" 또는 해당 "홈페이지"의 게시물"이 관련 법령 또는 "약관"이나 서비스 운영정책, 이용안내 등에 위반하는 것이 확인될 경우 "회사"는 제20조에 따라 "서비스" 이용계약을 해지할 수 있습니다.<br />
      제 9 조 (서비스의 제공 등)<br />
      ① "회사"가 제공하는 "서비스"는 원칙적으로 무료입니다. 다만, "회사"는 장래 시장상황의 변화, 정부 규제, "회사" 정책 등에 따라 "서비스"의 중대한 변경이 불가피한 경우 "서비스"를 "유료"로 전환할 수 있습니다. 또한 "서비스"에 별도의 유료서비스를 추가 또는 제공할 수 있으며 이 경우 "회사"는 제12조에 따른 방법으로 "회원"에게 통지합니다.<br />
      ② "회사"는 "회원"에게 아래와 같은 "서비스"를 제공합니다.<br />
      1. "홈페이지" 제작 및 운영을 위한 관리시스템의 제공<br />
      2. 제1호에 따라 제작 및 운영되는 "홈페이지"에 대한 도메인, 서버공간 등<br />
      3. 기타 "회원" 또는 "회원"의 "홈페이지" 운영/관리 등을 위한 제반 지원 서비스<br />
      ③ "회사"는 원칙적으로 제8조에 따라서 이용신청을 승낙한 때로부터 "서비스"를 연중무휴로 1일 24시간 제공합니다. 다만, "회사"의 기술상 또는 업무상의 장애로 인하여 "서비스"를 즉시 개시하지 못하는 경우 "회사"는 제12조에 따른 방법으로 "회원"에게 통지합니다.<br />
      ④ "회사"는 "회원"이 "홈페이지"의 운영현황확인 및 방문자 행태 분석을 할 수 있도록 "홈페이지"에 "회사"의 웹 로그 분석 서비스인 "NAVER Analytics"를 적용합니다.<br />
      제 10 조 (서비스의 변경)<br />
      ① "회사"는 "서비스"의 안정적인 제공 또는 개선을 위하여 운영상, 기술상 필요에 따라 제공하고 있는 "서비스"를 변경할 수 있습니다.<br />
      ② "회사"는 "서비스" 중요사항을 변경할 경우 변경내용과 적용일자를 명시하여 제12조에 따른 방법으로 사전에 통지합니다. 이때, "회원"의 권리나 의무 및 "서비스"이용과 관련되는 실질적인 사항을 변경할 경우 적용일자 7일 전부터 통지하며, "회원"에게 불리한 변경일 경우엔 적용일자 30일 전부터 통지합니다.<br />
      ③ "회원"은 "서비스" 변경에 동의하지 않을 경우 "회사"에 거부의사를 표시하고 "회원" 탈퇴를 할 수 있습니다.<br />
      ④ "회사"는 본 조 제1항에 따른 "서비스" 변경에 대하여 관련 법령에 특별한 규정이 없는 한 "회원"에게 별도로 보상하지 않습니다.<br />
      제 11 조 (서비스의 중단)<br />
      ① "회사"는 다음 각 호에 해당하는 경우 "서비스"의 일부 또는 전부의 제공을 일시적으로 중단할 수 있습니다.<br />
      1. 설비의 보수 등을 위하여 부득이한 경우<br />
      2. 전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지하는 경우<br />
      3. 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공에 지장이 있는 경우<br />
      4. 기타 회사가 서비스를 제공할 수 없는 위 제1호 내지 제3호에 준하는 사유가 발생한 경우<br />
      ② "회사"는 "회원"이 "회사"가 제공하는 통상적인 수준의 트래픽, 용량 등을 과도하게 초과하여 "회사"의 정상적인 "서비스" 제공에 지장이 있는 경우, "회원"에 대한 "서비스 "제공을 일시적으로 중단할 수 있으며, "회원"이 "서비스"의 중단 사유를 해소하는 경우에는 서비스 운영정책에 정하는 바에 따라 "서비스" 제공을 재개합니다.<br />
      ③ "회사"는 "서비스" 제공을 일시적으로 중단할 경우 중단사유와 중단기간을 명시하여 제12조에 따른 방법으로 사전에 통지합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우엔 사후에 통지할 수 있습니다.<br />
      ④ "회사"는 본 조 제1항, 제2항에 따른 "서비스" 제공 중단으로 발생한 "회원"의 손해에 대하여 고의 또는 중과실이 없는 한 책임을 지지 않습니다.<br />
      제 12 조 (회원에 대한 통지)<br />
      ① "회사"가 "회원"에 대하여 통지를 하는 경우 "약관"에 별도의 규정이 없는 한 서비스 내 전자우편, 전자쪽지, 로그인시 팝업창 등의 전자적 수단을 할 수 있습니다.<br />
      ② "회사"는 "회원" 전체에 대하여 통지를 하는 경우 "노타이틀 홈" 초기화면 또는 게시판에 게시함으로써 전항의 통지에 갈음할 수 있습니다. 다만, "회원"의 "서비스" 이용에 중대한 영향을 주는 사항에 대해서는 전항의 통지 수단 중 선택하여 통지합니다.<br />
      제 13 조 (회사의 권리와 의무)<br />
      ① "회사"는 관련 법령과 "약관"을 준수하며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.<br />
      ② "회사"는 "서비스" 이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정될 경우 이를 처리하여야 하며, 제12조에 따른 방법으로 "회원"에게 처리과정 및 결과를 전달할 수 있습니다.<br />
      ③ "회사"는 "회원"의 신원정보 확인을 위해 "회사"가 정한 일정한 범위의 정보 및 관련 서류의 제출을 요청할 수 있으며, 관련 법령에 따라 권한 있는 기관 등으로부터 요청을 받는 경우 해당 정보를 제공할 수 있습니다.<br />
      ④ "회원"은 전항의 의무를 소홀히 하여 발생한 불이익에 대해서는 보호받지 못합니다.<br />
      제 14 조 (회원의 의무)<br />
      ① "회원"은 관련 법령 및 본 약관, 운영정책 등을 준수하는 범위내에서 “회원” 본인의 홈페이지를 운영,관리하여야 하며, 이와 관련한 일체의 책임을 부담합니다.<br />
      ② "회원"은 "서비스"를 이용하는 과정에서 취득한 일체의 정보(제3자 정보 포함)를 거래의 이행에 필요한 목적 이외에 다른 용도로 사용하여서는 아니되며, 개인정보보호 등 관계 법령 의무를 준수하여야 합니다.<br />
      ③ "회원"은 "서비스" 이용과 관련하여 다음 각 호의 행위를 하여서는 아니 됩니다.<br />
      1. 다른 "회원"의 "아이디(ID)"를 부정 사용하는 행위<br />
      2. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위<br />
      3. 선량한 풍속, 기타 사회질서를 해하는 행위<br />
      4. 타인의 명예를 훼손하거나 모욕하는 행위<br />
      5. 타인의 지적재산권 등의 권리를 침해하는 행위<br />
      6. 동의 받지 않은 타인에 대한 개인정보를 수집 또는 저장하는 행위<br />
      7. 해킹행위 또는 컴퓨터바이러스의 유포행위<br />
      8. 타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송하는 행위<br />
      9. 통신사업자에 해당함에도 이를 표시하지 않고 기본적인 신원정보를 제공하지 않는 행위<br />
      10. "서비스"의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위<br />
      ("회사"의 서버 및 설비 등에 부하를 야기하는 행위 등)<br />
      11. 기타 관련 법령에 위배되는 행위<br />
      12. "서비스"를 본래의 목적에 어긋나게 사용하는 행위<br />
      13. 기타 상기 각호에 준하는 내용으로서 "회사"가 "서비스" 운영상 부적절하다고 판단하는 행위<br />
      ④ "회원"은 자신의 "아이디(ID)"를 "서비스"에 이용하기 위한 용도로만 사용하여야 하며, 이를 타인에게 대여 또는 양도하거나 타인의 "아이디(ID)"를 대여 또는 양도받아서는 안됩니다.<br />
      ⑤ "회원"은 "회사"가 본 조의 위반여부 등을 확인하기 위해 관련자료의 제공 및 소명 등을 요청하는 경우 이에 성실히 임해야 합니다.<br />
      ⑥ "회원"은 "서비스"를 이용하기 위해 업로드한 자료 등을 자체적으로 백업하여 보관할 의무가 있습니다.<br />
      제 15 조 (정보의 제공 및 방법)<br />
      ① "회사"는 "회원"이 "서비스" 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 "회원"에게 제공할 수 있습니다. 다만, "회원"은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.<br />
      ② 제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 "회원"의 사전 동의를 받아서 전송합니다. 다만, "회원"의 가입, 탈퇴, 거래관련 정보 및 고객문의 등에 대한 회신에 있어서는 제외됩니다.<br />
      ③ "회사"는 "서비스"의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 "회원"은 수신거절을 "회사"에게 할 수 있습니다.<br />
      제 16 조 (저작권의 귀속 등)<br />
      ① "회원"이 "서비스" 이용을 통해 제작한 "홈페이지 및 "홈페이지" 내 "게시물"의 저작권은 각각의 해당 저작자에게 귀속되며,"서비스"에 대한 저작권 및 지적재산권은 "회사"에 귀속됩니다.<br />
      ② "회사"는 서비스와 관련하여 "회원"에게 “회사”가 제공하는 제작 툴(에디터), 도메인, 서버공간 및 홈페이지 관리시스템 등을 이용할 수 있는 이용권만을 부여하며, "회원"은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.<br />
      ③ "회원"이 "서비스" 이용을 통해 제작한 "홈페이지" 및 "홈페이지" 내 "게시물"은 "회사"가 제공하는 “서비스” 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다.<br />
      ④ "회사"는 제2항 이외의 방법으로 "회원"의 "홈페이지" 및 "홈페이지" 내 "게시물"을 이용하고자 하는 경우에는 제12조에 따른 방법으로 "회원"에게 사전에 통지하여 동의를 얻어야 합니다.<br />
      제 17 조 (회원탈퇴 및 이용계약 해지 등)<br />
      ① "회원"은 언제든지 "노타이틀 홈" 또는 노타이틀 고객센터를 통하여 제8조에 따라 체결된 바에 따라 이를 즉시 처리하여야 합니다.<br />
      ② "회원" 탈퇴가 완료되는 경우, "회사"는 관련 법령 및 개인정보처리방침에 따라 보유하는 "회원"의 정보를 제외한 "회원"의 모든 정보 및 "회원"의 "아이디(ID)"에 등록된 "홈페이지" 및 "게시물" 정보 일체 등을 삭제합니다.<br />
      ③ "회원" 탈퇴 이후 서비스 운영정책에서 정하는 바에 따라 탈퇴한 "회원"의 정보로 다시 가입하는 것이 제한될 수 있습니다.<br />
      제 18 조 (이용제한 및 해제)<br />
      ① "회사"는 "회원"이 일정기간 이상 로그인하지 않는 경우, 회원정보의 보호 및 운영의 효율성을 위해 이용을 제한할 수 있습니다.<br />
      ② "회사"는 "회원"이 관련 법령, "약관" 또는 서비스 운영정책 등을 위반하는 경우 제8조에 따라 체결된 "서비스" 이용계약의 중단, 정지, 해지 및 회원 홈페이지내 개별 “게시물”에 대한 제한 등(이하 "이용제한"이라 합니다)의 조치를 취할 수 있습니다. "이용제한"의 구체적인 기준 등에 대해서는 운영정책에서 정합니다<br />
      ③ "회사"는 법원의 판결 이전이라도 아래 각 호의 사유가 있는 경우에는 그 사유를 고려하여 "이용제한"을 할 수 있습니다.<br />
      1. 관계 기관에서 현행 법령을 위반한 업체의 홈페이지 또는 현행 법령을 위반하는 게시물을 처리하는 홈페이지라고 판단하여 "서비스" 제공 중지를 요청해 온 경우<br />
      2. 방송통신심의위원회에서 시정요구 등을 해온 경우<br />
      3. 공정거래위원회, 경찰청 등의 국가기관 또는 서울시 전자상거래센터에서 이용자 피해를 유발하고 있는 "홈페이지"라고 판단하여 공지한 경우<br />
      4. 제3자로부터 명예훼손, 지적재산권 침해 등을 이유로 권리침해신고가 접수되는 경우<br />
      ④ "회사"는 제1항 또는 제2항에 따라 "이용제한"을 하는 경우 제12조에 따른 방법으로 "회원"에게 통지합니다. 이 경우 "회사"는 "이용제한"을 하기 전에 상당한 기간을 정하여 "회원"에게 이의신청의 기회를 부여함을 원칙으로 하며, 다만 운영정책에서 정하는 사유가 있을 경우엔 별도의 이의 신청 기간을 부여하지 않을 수 있습니다.<br />
      ⑤ "회원"은 본 조에 따라 제8조에 따라 체결된 "서비스" 이용계약의 이행중단 또는 "서비스" 이용정지를 받은 경우 해당 위반사유를 해소한 후 동 조치의 철회를 요청할 수 있습니다.<br />
      ⑥ "회사"가 본 조에 따라서 "이용제한"을 하는 경우 “회원”은 “이용제한”의 철회를 요청할 수 있으며, 해당 절차에 관한 구체적인 내용은 "서비스" 운영정책에서 정하는 바에 의합니다.<br />
      ⑦ 본 조에 따라 "회원" 자격이 박탈되는 경우, "회사"는 관련 법령 및 개인정보처리방침에 따라 보유하는 "회원"의 정보를 제외한 "회원"의 "아이디(ID)"에 등록된 "홈페이지" 및 "게시물" 정보 일체 등을 삭제합니다.<br />
      제 19 조 (약관의 해석)<br />
      ① "회사"는 "약관" 외에 별도의 서비스 운영정책 등을 둘 수 있습니다.<br />
      ② "약관"에서 정하지 않은 사항이나 해석에 대하여는 서비스 운영정책, 이용안내, 기타 관련 법령 또는 상관례에 따릅니다.<br />
      제 20 조 (책임제한)<br />
      ① "회사"는 천재지변, 디도스(DDOS) 공격, IDC 장애, 기간통신사업자의 회선 장애 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임을 지지 않습니다.<br />
      ② "회사"는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여는 책임을 지지 않습니다.<br />
      ③ "회원"이 "서비스" 이용을 통해 제작한 "홈페이지" 및 "홈페이지" 내 "게시물"의 내용에 관하여는 "회원"이 그에 대한 일체의 책임을 부담하며 “회사”는 이에 대해 어떠한 책임도 부담하지 않습니다.<br />
      ④ "회사"는 "회원" 간 또는 "회원"과 제3자 상호간의 "서비스"를 매개로 한 거래 등에 관하여 책임을 지지 않습니다.<br />
      제 21 조 (준거법 및 재판관할)<br />
      ① "회사"와 "회원" 간에 발생한 분쟁에 대하여는 대한민국법을 준거법으로 합니다.<br />
      ② "회사"와 "회원"간 발생한 분쟁에 관한 소송은 제소 당시의 "회원"의 주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로 합니다. 단, 제소 당시 "회원"의 주소 또는 거소가 명확하지 아니한 경우의 관할법원은 민사소송법에 따라 정합니다.<br />
      ③ 해외에 주소나 거소가 있는 "회원"의 경우 "회사"와 "회원"간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국 서울중앙지방법원을 관할법원으로 합니다.<br />
    </div>
  );
};

export default Terms1;