import { useEffect, useState } from "react"

export const useTitle = () => {
	const [title, setTitle] = useState(null);

	const updataTitle = () => {
		const htmlTitle = document.querySelector("title");
		htmlTitle.innerHTML = title;
	}
	useEffect(updataTitle, [title]);

	return setTitle;
}