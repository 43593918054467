import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Loading from "../common/Loading";
import { useNavigate } from "react-router-dom";
import Map from "../map/Map";
import PortfolioSlider from "../common/PortfolioSlider";
import { errorToast } from "../common/Toast";
import {
  FaTshirt,
  FaSpa,
  FaShoppingBasket,
  FaCouch,
  FaDesktop,
  FaFootballBall,
  FaBook,
  FaGlassCheers,
  FaAmbulance,
} from "react-icons/fa";
import { useTitle } from "../common/UseTitle";

const SearchResults = () => {
  const location = useLocation();
  const query = location.state?.query || null;
  const cat = location.state?.cat || "";
  const catCode = location.state?.catCode || "";
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [productList, setProductList] = useState([]);
  const [nearCmpnyItems, setNearCmpnyItems] = useState([]);
  const nearCmpny = JSON.parse(localStorage.getItem('nearCm')) ?? "";
  const [portfolio, setPortfolio] = useState([]);
  const [cmpny, setCmpny] = useState([])
  const [fetching, setFetching] = useState(false);
  const [pageInfo, setPageInfo] = useState({ page: 1, endPage: 0, check: false })

  const changeTitle = useTitle();

  const updateProductList = (newProductList) => {
    setProductList(newProductList);
  };

  const resetPage = () => {
    setPageInfo((prev) => ({
      ...prev,
      page: 1,
    }))
  }

  const updateInfo = (endPage, cmpnyNo) => {
    setPageInfo((prev) => ({
      ...prev,
      endPage: endPage,
      check: true,
      cmpnyNo: cmpnyNo
    }))
  }

  useEffect(() => {
    changeTitle("e층창고");
    setIsLoading(true);
    getProduct();
    nearItem();
    setPageInfo((prev) => ({
      ...prev,
      page: 1
    }))
  }, [catCode, query]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    if (pageInfo.page === pageInfo.endPage) {
      return;
    }
    if (productList.length === 0) {
      return;
    }
    if (scrollTop + clientHeight >= scrollHeight && fetching === false) {
      moreData(pageInfo.page);
      setPageInfo((prev) => ({
        ...prev,
        page: pageInfo.page + 1
      }));
    }
  };

  const getProduct = async () => {
    try {
      const tempJson = {
        echanggoYn: "Y",
        offset: 0,
        pageNumber: 0,
        pageSize: 40,
        paged: true,
      };
      if (catCode) {
        tempJson.bsnsTy = catCode;
      }
      if (query) {
        tempJson.prductNm = query;
      }
      const response = await fetch(
        "https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(tempJson),
        }
      );
      const prdList = await response.json();
      const prdListFiltered = prdList.content
        .filter((item) => item.prdImgFlpth !== null)
        .map((item) => {
          if (item.notitlePdCmPrice === null) {
            item.notitlePdCmPrice = 0;
          }
          if(!item.extrlImgUrl.includes("http")){
            item.extrlImgUrl = `https://cloud.1472.ai:18443/images/${item.extrlImgUrl}`;
          }
          return item;
        });
        console.log(prdListFiltered)
      setProductList(prdListFiltered);
      setPageInfo((prev) => ({
        ...prev,
        endPage: prdList.totalPages,
        totalElements: prdList.totalElements
      }))
      getCmpnyList(query);
    } catch (error) {
      console.error(error);
    }
  };

  const moreData = async (i) => {
    setFetching(true);
    try {
      const tempJson = {
        echanggoYn: "Y",
        offset: i * 40,
        pageNumber: i,
        pageSize: 40,
        paged: true,
      };
      if (catCode) {
        tempJson.bsnsTy = catCode;
      }
      if (query) {
        tempJson.prductNm = query;
      }
      if (pageInfo.check) {
        tempJson.cmpnyNo = pageInfo.cmpnyNo;
      }
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tempJson),
      });
      const prdList = await response.json();
      const prdListFiltered = prdList.content
        .filter((item) => item.prdImgFlpth !== null)
        .map((item) => {
          if (item.notitlePdCmPrice === null) {
            item.notitlePdCmPrice = 0;
          }
          if(!item.extrlImgUrl.includes("http")){
            item.extrlImgUrl = `https://cloud.1472.ai:18443/images/${item.extrlImgUrl}`;
          }
          return item;
        });
      setProductList((prevProductList) => [...prevProductList, ...prdListFiltered]);
    } catch (error) {
      console.error(error);
    }
    setFetching(false);
  }

  const getPortfolio = async () => {
    if (!query) {
      setIsLoading(false);
      return;
    }
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/portMgmt/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keyword: query,
          useYn: "Y",
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        }),
      });
      const data = await response.json();
      const dummy = data.content
      //const portfolioFiltered = dummy.filter((item) => item.imgUrl.includes("images/cloud/"));
      setPortfolio(dummy);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  const getCmpnyList = async (query) => {
    try {
      const tempJson = {
        echanggoYn: "Y",
        offset: 0,
        pageNumber: 0,
        pageSize: 40,
        paged: true,
      };
      if (catCode) {
        tempJson.bsnsTy = catCode;
      }
      if (query) {
        tempJson.prductNm = query;
      }
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductGrpCmpnyStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tempJson),
      });
      const data = await response.json();
      const dummy = data.content;
      const newCmpnyListPromises = dummy.map((item) => getCmpnyNm(item));
      const newCmpnyList = await Promise.all(newCmpnyListPromises);
      setCmpny({ count: data.totalElements, list: newCmpnyList })
      getPortfolio();
    } catch (error) {
      console.error(error);
    }
  }

  const getCmpnyNm = async (item) => {
    const url = `https://cloud.1472.ai:18443/api/v2/cmmn/cmpnyInfo/${item.cmpnyNo}`;

    return fetch(url, { method: "POST", headers: { accept: "*/*" } })
      .then((response) => response.json())
      .then((data) => {
        const adres = data.adres;
        const telNo = data.telno;
        return { ...item, adres, telNo };
      })
      .catch((error) =>
        console.error("There was a problem with the fetch operation:", error)
      );
  };

  const nearItem = async () => {
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          echanggoYn: "Y",
          cmpnyNo: nearCmpny.cmpnyNo,
          offset: 0,
          pageNumber: 0,
          pageSize: 100,
          paged: true,
        }),
      })
      const data = await response.json();
      const dummy = data.content;
      const selectedIndexes = [];
      if (dummy.length > 3) {
        while (selectedIndexes.length < 3) {
          const randomIndex = Math.floor(Math.random() * data.content.length);
          if (!selectedIndexes.includes(randomIndex)) {
            selectedIndexes.push(randomIndex);
          }
        }
        const newArray = selectedIndexes.map(index => data.content[index]);
        setNearCmpnyItems(newArray);
      } else {
        setNearCmpnyItems(data.content);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleClick = (product) => {
    navigate("/product-detail", {
      state: { product: product },
    });
  };

  const renderIcon = () => {
    let icon;
    switch (catCode) {
      case "BTY002":
        icon = <FaTshirt />;
        break;
      case "BTY003":
        icon = <FaSpa />;
        break;
      case "BTY004":
        icon = <FaShoppingBasket />;
        break;
      case "BTY005":
        icon = <FaCouch />;
        break;
      case "BTY006":
        icon = <FaDesktop />;
        break;
      case "BTY007":
        icon = <FaFootballBall />;
        break;
      case "BTY008":
        icon = <FaBook />;
        break;
      case "BTY009":
        icon = <FaGlassCheers />;
        break;
      case "BTY010":
        icon = <FaAmbulance />;
        break;
      default:
        icon = null;
    }
    return icon;
  }

  if (isLoading) {
    return (
      <Container>
        <Row>
          <Col>
            {isLoading && (
              <div
                style={{
                  height: "300px",
                }}
              >
                <Loading />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  if (productList.length === 0 && portfolio.length === 0) {
    return (
      <Container style={{ marginTop: "7em" }}>
        <Row>
          <Col>
            {areaCode.includes(query) ? (
              <h2 className="mb-4">'{query}' 지역에는 {cmpny.count}개의 업체가 있습니다.</h2>
            ) : (
              cat ? (
                <h2 className="mb-4">{cat} {renderIcon()} 상품이 없습니다.</h2>
              ) : (
                <>
                  <h2 className="mb-4">'{query}'에 대한 검색결과가 없습니다.</h2>
                  {
                    nearCmpnyItems.length !== 0 ? (
                      <>
                        <Row className="my-5" style={{ marginTop: '3em' }}>
                          <Col>
                            <h2>내 주변 상품</h2>
                          </Col>
                        </Row>
                        <Row className="my-5">
                          {
                            nearCmpnyItems.map((item) => (
                              <Col md={4} key={item.prdNo}>
                                <Card>
                                  <Card.Img
                                    variant="top"
                                    src={`https://cloud.1472.ai:18443/images/cloud/uploads/${item.prdImgFlpth}`}
                                  />
                                  <Card.Body>
                                    <Card.Text>{item.prductNm}</Card.Text>
                                    <Card.Text>
                                      {item.notitlePdCmPrice?.toLocaleString()}원
                                    </Card.Text>
                                    <Button
                                      variant="primary"
                                      style={{ backgroundColor: "#F39802", border: 0 }}
                                      onClick={() => {
                                        handleClick(item);
                                      }}
                                    >보러가기</Button>
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))
                          }
                        </Row>
                      </>
                    ) : null
                  }
                </>
              )
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div>
      <Row style={{ marginTop: "7em" }}>
        <Col>
          {areaCode.includes(query) ? (
            <h2 className="mb-4">'{query}' 지역에는 {cmpny.count}개의 업체가 있습니다.</h2>
          ) : (
            cat ? (
              <h2 className="mb-4">{cat} {renderIcon()}</h2>
            ) : (
              <h2 className="mb-4">'{query}'에 대한 검색결과</h2>
            )
          )}
        </Col>
      </Row>
      <Map cmpnyList={cmpny.list} updateProductList={updateProductList} query={query} updateInfo={updateInfo}
        resetPage={resetPage}
      />
      <Container style={{ marginBottom: "7rem" }}>
        {
          portfolio.length !== 0 ? (<PortfolioSlider portfolio={portfolio} />) : (null)
        }
        {
          productList.length !== 0 ? (<div style={{ textAlign: "left", fontWeight: 800, marginTop: "3rem", fontSize: "1.4rem" }}>
            <span>총 상품 {pageInfo.totalElements} 개</span>
          </div>) : (null)
        }
        <Row>
          {productList.map((product) => (
            <Col
              key={`${product.prdNo}|${product.cmpnyNo}`}
              md={3}
              xs={6}
              style={{ marginTop: "1.7em", marginBottom: "2rem" }}
            >
              <Card onClick={() => {
                handleClick(product);
              }} style={{ height: "80%", border: 0 }}>
                <Card.Img
                  variant="top"
                  src={
                    product?.extrlImgUrl
                      ? product.extrlImgUrl
                      : `https://cloud.1472.ai:18443/images/${product?.prdImgFlpth}`
                  }
                  style={{ objectFit: "contain", height: "90%" }}
                />
                <Card.Body style={{ textAlign: "left", padding: "0" }}>
                  <Card.Text style={{ fontSize: "15px", marginBottom: "0.4rem", marginTop: "1rem" }}>
                    <span className="badge badge-primary" style={{ backgroundColor: "#F39802", marginRight: "0.5rem" }}>{product?.cmpnyNm}</span>
                    <span className="badge badge-primary" style={{ border: "1px solid #F39802", color: "#F39802", backgroundColor: "white" }}>{product.areaNm}</span>
                    {
                      product.salesStatus === "S03" ?
                        (<span className="badge badge-primary" style={{ backgroundColor: "red", marginLeft: "0.5rem" }}>품절</span>) : (null)
                    }
                  </Card.Text>
                  <Card.Title style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    marginBottom: "8px",
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: product.salesStatus === "S03" ? "#ddd" : "black"
                  }}>{product.prductNm}</Card.Title>
                  <Card.Text style={{ fontSize: "20px", fontWeight: "700", color: product.salesStatus === "S03" ? "#ddd" : "black" }}>{product.notitlePdCmPrice.toLocaleString()}원</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {
          fetching ? (<div
            style={{
              height: "300px",
            }}
          >
            <Loading />
          </div>) : null
        }
      </Container>
    </div >
  );
};

export default SearchResults;
