import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorToast } from '../common/Toast';
import { useSelector } from 'react-redux';

const OrderCancel = () => {
  const location = useLocation();
  const [checked, setChecked] = useState([]);
  const isLoggedIn = useSelector(state => state.isLoggedIn);
  const userInfo = useSelector(state => state.user) ?? [];
  const navigate = useNavigate();
  const secretKey = 'test_sk_OEP59LybZ8Bdy9Q1kRWV6GYo7pRe:'
  const ord = location.state && location.state.ord;
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    if (!ord) {
      if (!isLoggedIn) {
        navigate('/login');
      } else {
        alert("올바르지 않은 접속입니다.");
        navigate("/");
      }
    }
  }, [])

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const cancel = (event) => {
    event.preventDefault();
    if (checked.length === 0) {
      errorToast("상품을 선택해주세요.");
      return;
    }

    if (!selectedOption) {
      errorToast("교환 / 반품을 선택해주세요.")
      return;
    }
    const reason = event.target.elements.reason.value;
    const content = event.target.elements.content.value;

    // if (location.state.ord.delYn === "Y") {
    //   errorToast("이미 취소된 상품입니다.");
    //   return;
    // }

    if (!content) {
      errorToast("취소 사유를 입력하세요.");
      return;
    }
    let amount = 0;
    checked.map((item) => {
      amount = amount + (item.cost * item.cancelQy);
    })
    // 불량, 변심에 따른 택배비 부담
    // if (event.target.elements.reason.value === "1") {
    //   amount = amount + 3000
    // }

    rtnOrd(content, reason, amount);
  }

  // 결제취소
  const paymentsCancel = async (content, amount) => {
    try {
      const response = await fetch(`https://api.tosspayments.com/v1/payments/${ord.ordId}/cancel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(secretKey),
        },
        body: JSON.stringify({
          cancelAmount: amount,
          cancelReason: content
        }),
      });
      const data = await response.json();
      console.log(data.cancels !== null);
      console.log(data);
      // navigate("/order-detail", {
      //   state: {orderNum: }
      // })
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  }

  const rtnOrd = async (content, reason, amount) => {
    let newData = {};
    let dtlList = [];
    ord.dtlList.map((item) => {
      newData = {
        ordDtlNo: item.ordDtlNo,
        ordNo: item.ordNo,
        prdNo: item.prdNo,
        prductNm: item.prductNm,
        setNo: item.setNo,
        totAmount: item.totAmount,
        prductCl: item.prductCl,
        qy: item.qy,
        cost: item.cost,
        m: item.m,
        n: item.n,
        supplyAmt: item.supplyAmt,
        tax: item.tax,
        ordQy: item.ordQy,
        bomSetSe: item.bomSetSe,
        asPd: item.asPd,
        prmpc: item.prmpc,
        prmpcSm: item.prmpcSm,
        profitAmt: item.profitAmt,
        selngEntrpsNo: item.selngEntrpsNo,
        socNo: item.socNo,
        dlivMatter: item.dlivMatter,
        vendnm: item.vendnm,
        delYn: item.delYn,
        repImg: item.repImg,
        selngDataNo: item.selngDataNo,
        dlvPrpNo: item.dlvPrpNo,
        ordReqNo: item.ordReqNo,
        setYn: item.setYn,
        rtnSe: item.rtnSe,
        rtnReason: item.rtnReason,
      }
      checked.map((obj) => {
        if (item.ordDtlNo === obj.ordDtlNo) {
          if (reason === "1") {
            newData.rtnSe = "RTNS02"
          } else if (reason === "2") {
            newData.rtnSe = "RTNS01"
          }
          newData.rtnReason = content
        }
      })
      dtlList.push(newData);
    })

    const response = await fetch("https://erp.1472.ai:28443/api/v1/ord", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ordVo: {
          dlvySptAdres: ord.dlvySptAdres,
          dtlList: dtlList,
          ordNo: ord.ordNo,
          ordCmpnyNo: ord.ordCmpnyNo, // 주문회사번호
          ordMberNo: ord.ordMberNo, // 주문회원번호
          wrter: ord.wrter, // 작성자
          ordSttusCl: ord.ordSttusCl, // 주문상태분류
          progrsSttus: ord.progrsSttus, // 진행상태
          writeDt: ord.writeDt, // 작성일시
          ordDt: ord.ordDt, // 주문일시
          dedtDe: ord.dedtDe, // 납기일자
          bcncNo: ord.bcncNo, // 거래처번호
          bcncNm: ord.bcncNm, // 거래처명
          ordId: ord.ordId, //주문아이디
          bcncTelno: ord.bcncTelno, // 거래처전화번호
          sptNm: ord.sptNm, // 현장명 넣기
          sptAdres: ord.sptAdres, // 현장주소 넣기
          dlvyMth: ord.dlvyMth, // 배송방법
          dlvyCt: ord.dlvyCt, // 배송비
          rcpnt: ord.rcpnt, // 수령자
          dept: ord.dept, // 부서
          vatCl: ord.vatCl, // 부가세분류
          demandTot: ord.demandTot, // 출고액합계
          trnsprtDvyfgAm: ord.trnsprtDvyfgAm, // 운송,납품액
          bndePrductCl: ord.bndePrductCl, // 일괄제품분류
          bndeMargin: ord.bndeMargin, // 일괄마진
          bndeNego: ord.bndeNego, // 일괄네고
          sumry: ord.sumry, // 적요
          ordCours: ord.ordCours, // 주문경로
          ordCrcmstncs: ord.ordCrcmstncs, // 주문경위
          buyNo: ord.buyNo, // 매입관리번호
          selngNo: ord.selngNo, // 매출관리번호
          faxYn: ord.faxYn, // 팩스여부
          prtYn: ord.prtYn, // 출력여부
          dlivyYn: ord.dlivyYn, // 출고여부
          bilclctYn: ord.bilclctYn, // 수금여부
          delYn: ord.delYn, // 삭제여부
          schRegYn: ord.schRegYn // 일정등록여부
        }
      })
    })
    const data = await response.json();
    console.log(data);
    paymentsCancel(content, amount);
  }

  // 주문취소
  const cancleOrd = async (item) => {
    try {
      const response = await fetch("https://erp.1472.ai:28443/api/v1/cancelOrd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([{
          ordNo: item.ordNo,
          ordMberNo: userInfo.mberNo
        }]),
      });
      const data = await response.json();
      console.log(data);
      if (data.success) {
        successToast("주문을 취소했습니다.");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleCheck = (item, e) => {
    if (e.target.checked) {
      const selectValue = e.target.closest('div').querySelector('select[name="qy"]').value;
      setChecked(prevChecked => [...prevChecked, { ...item, cancelQy: selectValue }]);
    } else {
      setChecked(prevChecked => prevChecked.filter(checkedItem => checkedItem.ordDtlNo !== item.ordDtlNo));
    }
  };

  const handleQty = (item, e) => {
    const selectValue = e.target.closest('div').querySelector('select[name="qy"]').value;
    setChecked(prevChecked => prevChecked.map(checkedItem => {
      if (checkedItem.ordDtlNo === item.ordDtlNo) {
        return { ...checkedItem, cancelQy: selectValue };
      }
      return checkedItem;
    }))
  }

  return (
    <Container className='py-5' style={{ marginTop: "5em", marginBottom: "5em" }}>
      <h2>
        교환 / 환불
      </h2>
      <Row>
        <Col>
          <Form style={{ maxWidth: " 800px", margin: "0 auto" }} onSubmit={cancel}>
            <Form.Group>
              <Form.Label>취소 상품</Form.Label>
              {ord &&
                ord.dtlList.map((item) => (
                  <div key={item.ordDtlNo} style={{ display: "flex", marginBottom: "1rem" }}>
                    <Form.Control
                      disabled={item.rtnSe ? true : false}
                      style={{ minWidth: "20px" }}
                      type='checkbox'
                      checked={checked.some(checkedItem => checkedItem.ordDtlNo === item.ordDtlNo)}
                      onChange={(e) => {
                        handleCheck(item, e);
                      }} />
                    <img src={`https://cloud.1472.ai:18443/images/cloud/uploads/${item.repImg}`} style={{ width: 100 }} />
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "1rem", textAlign: "left" }}>
                      <span>{item.prductNm}</span>
                      <span>{item.cost.toLocaleString()}원</span>
                    </div>
                    <div style={{ flexGrow: 1 }}></div>
                    <div>
                      <Form.Select
                        style={{ width: "100px" }}
                        aria-label="취소 수량"
                        className="form-control form-control-sm"
                        name='qy'
                        onChange={(e) => {
                          handleQty(item, e);
                        }}
                      >
                        {(() => {
                          const options = [];
                          for (let i = 1; i <= item.qy; i++) {
                            options.push(
                              <option key={i} value={i}>{i}</option>
                            );
                          }
                          return options;
                        })()}
                      </Form.Select>
                    </div>
                  </div>
                ))
              }
            </Form.Group>
            <Form.Select
              id='reason'
              style={{ width: "200px", marginLeft: "auto" }}
              className="form-control form-control-sm">
              <option value="1">불량 및 파손</option>
              <option value="2">단순 변심</option>
            </Form.Select>
            <Form.Group style={{ marginBottom: "1rem" }}>
              <Form.Label>상세 사유 입력</Form.Label>
              <Form.Control as="textarea" rows={5} name='content' id='content' />
            </Form.Group>
            <Form.Group style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Form.Label style={{ margin: 0 }} htmlFor='refund'>교환</Form.Label>
              <Form.Control
                id='refund'
                style={{ width: "20px" }}
                type="radio"
                name='refundOrExchange'
                onChange={handleRadioChange}
                value='교환'
              />
              <div style={{ width: "100px" }}></div>
              <Form.Label style={{ margin: 0 }} htmlFor='exchange'>반품</Form.Label>
              <Form.Control
                id='exchange'
                style={{ width: "20px" }}
                type='radio'
                name='refundOrExchange'
                onChange={handleRadioChange}
                value='반품'
              />
            </Form.Group>
            <Button type='submit'
              variant="primary"
              style={{ backgroundColor: "#F39802", border: 0 }}>신청하기</Button>
          </Form>
        </Col>
      </Row>
    </Container >
  );
};

export default OrderCancel;