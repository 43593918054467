import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: #f8f9fa;
  margin-top: 3rem;
`;

const Title = styled.h2`
  margin-top: 3em;
  margin-bottom: 5em;
`;

const ProductDetailContents = ({ prdDetail }) => {
  return (
    <Wrapper>
      <Title>{prdDetail.prductNm}</Title>
      {prdDetail.dtlHtmlCn && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowX: "scroll",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            dangerouslySetInnerHTML={{
              __html: prdDetail.dtlHtmlCn
                .replace(
                  /http:\/\/cloud\.1472\.ai:8080/g,
                  "https://cloud.1472.ai:18443"
                )
                .replace(
                  /<img/g,
                  '<img onerror="this.style.display=\'block\' " alt="이미지 준비중 입니다." style="width: 100%" '
                ),
            }}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default ProductDetailContents;
