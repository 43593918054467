// 로그인 정보를 세션 스토리지에 저장하는 함수
function saveLoginInfoToSessionStorage(user) {
  sessionStorage.setItem('user', JSON.stringify(user));
}
  
// 로그인 정보를 세션 스토리지에서 가져오는 함수
function getLoginInfoFromSessionStorage() {
  const user = sessionStorage.getItem('user');
  return user ? JSON.parse(user) : null;
}
  
// 초기 상태를 세션 스토리지에서 가져와서 설정하는 함수
function getInitialState() {
  const user = getLoginInfoFromSessionStorage();
  return {
    isLoggedIn: !!user,
    user: user,
    products: [],
    totalPrice: 0,
    shippingFee: 0,
    shippingInfo: {},
    recipientInfo: {},
  };
}
  
const initialState = getInitialState();
// 초기 상태값 정의
  
function reducer(state = initialState, action) {

    switch (action.type) {
      case "LOGIN":
        saveLoginInfoToSessionStorage(action.payload);
        return {
          ...state,
          isLoggedIn: true,
          user: action.payload
        };
      case "LOGOUT":
        sessionStorage.removeItem('user');
        window.location.href = '/';
        return {
          ...state,
          isLoggedIn: false,
          user: null
        };
      case 'SET_PRODUCTS':
        return { ...state, products: action.payload };
      case 'SET_TOTAL_PRICE':
        return { ...state, totalPrice: action.payload };
      case 'SET_SHIPPING_FEE':
        return { ...state, shippingFee: action.payload };
      case 'SET_SHIPPING_INFO':
        return { ...state, shippingInfo: action.payload };
      case 'SET_RECIPIENT_INFO':
        return { ...state, recipientInfo: action.payload };
      default:
        return state;
  }
}
  
export default reducer;