import React, { useState, useEffect } from "react";
import { Button, Col, Container, Overlay, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MainMap = ({ productList }) => {
  const [mx, setMx] = useState(1100);
  const [draggable, setDraggable] = useState(true);
  const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const navigate = useNavigate();

  const handleMxChange = (newMx) => {
    setMx(newMx);
  }

  useEffect(() => {
    const handleDocumentClick = async (e) => {
      const target = e.target;
      const clickBox = document.querySelector('.clickBox');
      const cmpnyNoElement = document.querySelector('.cmpnyNo');
      const cmpnyNo = cmpnyNoElement ? cmpnyNoElement.value : '';
      let cpInfo = ""
      if (cmpnyNo !== "") {
        cpInfo = await getCmpnyNm(cmpnyNo).then(result => result);
      }
      cpInfo = { ...cpInfo, telNo: cpInfo.telno }
      if (clickBox && clickBox.contains(target)) {
        navigate("/company-pdlist", {
          state: { product: cpInfo }
        })
      }
    };
    const clickHandler = (e) => handleDocumentClick(e);
    document.addEventListener("click", clickHandler);

    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, []);

  const getCmpnyNm = async (item) => {
    const url = `https://cloud.1472.ai:18443/api/v2/cmmn/cmpnyInfo/${item}`;

    return fetch(url, { method: "POST", headers: { accept: "*/*" } })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) =>
        console.error("There was a problem with the fetch operation:", error)
      );
  };

  useEffect(() => {
    const script = document.createElement("script");
    const SERVER_APP_KEY = "dfb8211e9a5553f8050c65582853a961";
    const LOCAL_APP_KEY = "8400068f9c2268cfa77c552875a9ac80";
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${SERVER_APP_KEY}&autoload=false&libraries=services`;

    document.head.appendChild(script);
    let list = [];
    let near = [];
    productList.forEach((item) => {
      const index = list.findIndex((obj) => obj.cmpnyNo === item.cmpnyNo);
      if (index === -1) {
        list.push({ cmpnyNo: item.cmpnyNo, adres: item.adres, cmpnyNm: item.cmpnyNm, telNo: item.telNo });
      }
    });

    script.onload = () => {
      window.kakao.maps.load(() => {
        const { kakao } = window;

        navigator.geolocation.getCurrentPosition((position) => {
          const { latitude, longitude } = position.coords;

          const container = document.getElementById("map");
          const options = {
            center: new kakao.maps.LatLng(latitude, longitude),
            level: 3,
          };
          const map = new kakao.maps.Map(container, options);
          const zoomControl = new kakao.maps.ZoomControl();
          map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

          const markerImage = new kakao.maps.MarkerImage(
            "/newMan2.png",
            new kakao.maps.Size(40, 40),
            {
              spriteOrigin: new kakao.maps.Point(0, 0),
              spriteSize: new kakao.maps.Size(40, 40),
            }
          );
          const marker = new kakao.maps.Marker({
            position: new kakao.maps.LatLng(latitude, longitude),
            map: map,
            image: markerImage,
          });
          const content =
            '<div class ="label" style="background-color:#2b2d36; box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); margin-top: 30px; border-radius: 5px; padding: 0 10px;"><span class="center" style="color: #eee;">현재위치</span></div>';
          const customOverlay = new kakao.maps.CustomOverlay({
            position: new kakao.maps.LatLng(latitude, longitude),
            content: content,
          });
          customOverlay.setMap(map);
          const polyline = new kakao.maps.Polyline();

          const customOverlays = [];

          const geocoder = new window.kakao.maps.services.Geocoder();
          list.forEach((item) => {
            geocoder.addressSearch(item.adres, (result, status) => {
              if (status === window.kakao.maps.services.Status.OK) {
                const newPath = [
                  new kakao.maps.LatLng(latitude, longitude),
                  new kakao.maps.LatLng(result[0].y, result[0].x)
                ];
                polyline.setPath(newPath);
                const length = polyline.getLength();

                if (length <= 4000) { // 현재위치 2키로 안에만 렌더링
                  near.push(length);
                  const minLength = Math.min(...near);
                  if (minLength === length) {
                    localStorage.setItem("nearCm", JSON.stringify(item));
                  }
                  const markerImage = new kakao.maps.MarkerImage(
                    "/newMarker2.png",
                    new kakao.maps.Size(30, 40),
                    {
                      spriteOrigin: new kakao.maps.Point(0, 0),
                      spriteSize: new kakao.maps.Size(30, 40),
                    }
                  );

                  const marker = new window.kakao.maps.Marker({
                    position: new window.kakao.maps.LatLng(result[0].y, result[0].x),
                    map: map,
                    image: markerImage,
                    zIndex: 1
                  });

                  let phone;
                  if (!item.telNo) {
                    phone = "번호없음";
                  } else {
                    phone = item.telNo;
                    if (phone.length === 11) {
                      phone = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
                    } else if (phone.length === 9) {
                      phone = phone.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
                    } else if (phone.length === 10) {
                      phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                    } else if (phone.includes("-")) {
                      phone = phone;
                    } else {
                      phone = "올바른 형식의 번호가 아닙니다.";
                    }
                  }

                  const content =
                    `<div class="overlaybox">` +
                    `    <div class="boxtitle">${item.cmpnyNm}</div>` +
                    '    <div class="">' +
                    "    </div>" +
                    "    <ul>" +
                    '        <li>' +
                    '            <span class="number"></span>' +
                    `            <span class="title">${item.adres}</span>` +
                    '        </li>' +
                    '        <li>' +
                    '            <span class="number"></span>' +
                    `            <span class="title">${phone}</span>` +
                    `            <input type='hidden' class='cmpnyNo' value=${item.cmpnyNo}></input>` +
                    '        </li>' +
                    '        <li>' +
                    '            <span class="title clickBox">판매자 제품 모두보기</span>' +
                    '        </li>' +
                    "   </ul>" +
                    '</div>';
                  const customOverlay = new window.kakao.maps.CustomOverlay({
                    position: new window.kakao.maps.LatLng(result[0].y, result[0].x),
                    content: content,
                    xAnchor: 0.5,
                    yAnchor: 1.55,
                    zIndex: 1
                  });
                  const content2 =
                    `<div class ="label" style="background-color:#2b2d36; box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4); margin-top: 30px; border-radius: 5px; padding: 0 10px;"><span class="center" style="color: #eee;">${item.cmpnyNm}</span></div>`;
                  const customOverlay2 = new window.kakao.maps.CustomOverlay({
                    position: new window.kakao.maps.LatLng(result[0].y, result[0].x),
                    content: content2,
                    xAnchor: 0.5,
                    yAnchor: 0.5,
                  })
                  customOverlay2.setMap(map);

                  kakao.maps.event.addListener(marker, "click", function () {
                    customOverlays.forEach((overlay) => {
                      if (overlay !== customOverlay) {
                        overlay.setMap(null);
                      }
                    });

                    customOverlay.setMap(map);
                  });
                  customOverlays.push(customOverlay)
                }

              } else {
                console.error("Failed to search address");
              }
            })
          })

          const closeUp = document.getElementById("closeUpBtn");
          closeUp?.addEventListener("click", async function () {
            const user = navigator.userAgent;
            let isCheck = false;
            if (user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1) {
              isCheck = true;
            }
            let newMx = 2500;
            const map = document.getElementById("map");
            if (map.style.height === "400px") {
              map.style.height = isCheck ? (screenHeight - 210) + "px" : "900px"
              map.style.width = "100%";
            }
            await handleMxChange(newMx);
            centerLayout();
          });

          const closeUp2 = document.getElementById("closeUpBtn2");
          closeUp2.addEventListener("click", async function () {
            let newMx = 1100;
            const map = document.getElementById("map");
            if (map.style.height !== "400px") {
              map.style.height = "400px";
              map.style.width = "100%";
            }
            await handleMxChange(newMx);
            centerLayout();
          })

          const centerLayout = () => {
            map.relayout();
            map.setCenter(new kakao.maps.LatLng(latitude, longitude));
          }
        }, (error) => {
          console.error(error);
        });
      });
    };
  }, []);

  return (
    <Container style={{ marginBottom: "60px", marginTop: "8em", maxWidth: `${mx}px` }}>
      <Row>
        <Col>
          <div onClick={(() => {
            setDraggable(false);
          })} id="closeUpBtn" style={{ width: "100%", height: "400px", position: "absolute", zIndex: draggable ? 2 : -10 }}></div>
          <div id="map" style={{ width: "100%", height: "400px" }} ></div>
          <div style={{ position: "absolute", zIndex: 8889, bottom: 0, right: 15 }}>
            <Button size="sm"
              onClick={() => {
                setDraggable(true);
              }}
              style={{ backgroundColor: "#F39802", border: 0, display: draggable ? "none" : "block", padding: "10px 20px", fontSize: "0.9rem" }}
              variant="primary" id="closeUpBtn2">
              축소
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );

}

export default MainMap;