import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  Form,
  Button,
  ListGroup,
} from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import Loading from '../common/Loading';
import { errorToast, successToast } from '../common/Toast';
import Modal from '../common/Modal';

const OrderDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => state.isLoggedIn);
  const userInfo = useSelector(state => state.user) ?? [];
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ord, setOrd] = useState([]);
  const [cancelNo, setCancelNo] = useState(0);
  const [payments, setPayments] = useState(false);
  const secretKey = 'test_sk_OEP59LybZ8Bdy9Q1kRWV6GYo7pRe:'
  const orderNum = location.state && location.state.orderNum;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      if (!orderNum) {
        alert("올바르지 않은 접속입니다.");
        navigate("/");
      } else {
        getOrd();
      }
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  }

  useEffect(() => {
    if (ord.length !== 0) {
      orderNum.sort((a, b) => a - b);
      let ordNo = "";
      orderNum.map((obj) => {
        ordNo = ordNo + obj + "_";
      })
      ordNo = ordNo.substring(0, ordNo.length - 1);
      const orderId = ord[0].ordId + "_" + ordNo;
      getPayments(orderId);
    }
  }, [ord]);

  const getOrd = async () => {
    try {
      await Promise.all(orderNum.map(async (item) => {
        const response = await fetch("https://erp.1472.ai:28443/api/v1/ordInqry", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ordNo: item,
            ordMberNo: userInfo.mberNo,
            offset: 0,
            pageNumber: 0,
            pageSize: 10,
            paged: true
          })
        });
        const data = await response.json();
        const dummy = data.content;
        const newListPromises = dummy.map((item) => getDtlList(item));
        const newList = await Promise.all(newListPromises);
        console.log(newList);
        setOrd((list) => [...list, newList[0]])
      }))
    } catch (error) {
      console.error(error);
    }
  }

  const getDtlList = async (item) => {
    return fetch("https://erp.1472.ai:28443/api/v1/ordDtlList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ordNo: item.ordNo,
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: true
      })
    })
      .then((response) => response.json())
      .then((data) => {
        const dtlList = data.content;
        return { ...item, dtlList };
      })
  }

  // 결제정보
  const getPayments = async (orderId) => {
    try {
      const response = await fetch(`https://api.tosspayments.com/v1/payments/orders/${orderId}`, {
        method: "GET",
        headers: {
          "Authorization": "Basic " + btoa(secretKey)
        }
      });
      const data = await response.json();
      console.log(data);
      if (data.code === "NOT_FOUND_PAYMENT") {
        setIsLoading(false);
        return;
      } else {
        setPayments(data);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleClick = async (item, dtl) => {
    let product;
    const prdNo = dtl.prdNo.split("_")[0];
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cmpnyNo: item.bcncNo,
          prdNo: prdNo,
          echanggoYn: "Y",
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        })
      })
      const data = await response.json();
      console.log(data);
      product = data.content[0];

    } catch (error) {
      console.error(error);
    }
    navigate("/product-detail", {
      state: { product: product }
    })
  }

  // 주문취소
  const cancleOrd = async () => {
    try {
      const response = await fetch("https://erp.1472.ai:28443/api/v1/cancelOrd", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify([{
          ordNo: cancelNo.ordNo,
          ordMberNo: userInfo.mberNo
        }]),
      });
      const data = await response.json();
      console.log(data);
      if (data.success) {
        successToast("주문을 취소했습니다.");
        setOrd("");
        setIsLoading(true);
        await getOrd();
      }
    } catch (error) {
      console.error(error);
    }
  }


  // 결제취소
  const paymentsCancel = async () => {
    try {
      const response = await fetch(`https://api.tosspayments.com/v1/payments/${cancelNo.tossOrdId}/cancel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(secretKey),
        },
        body: JSON.stringify({
          cancelAmount: cancelNo.demandTot + cancelNo.trnsprtDvyfgAm,
          cancelReason: "삭제테스트"
        }),
      });
      const data = await response.json();
      console.log(data);
      cancleOrd();
    } catch (error) {
      console.error(error);
    }
  }

  const renderDelyStatus = (item) => {
    let render = "";
    switch (item.progrsSttus) {
      case "OWS011":
        render = "입금전";
        break;
      case "OWS012":
        render = "입금완료";
        if (item.ordSrcNo !== null) {
          render = "배송준비중";
        }
        break;
      case "OWS013":
        render = "출고준비";
        if (item.ordSrcNo !== null) {
          render = "배송준비중";
        }
        break;
      case "OWS014":
        render = "배송중";
        break;
      case "OWS015":
        render = "배송완료";
        break;
      default:
        render = "정보가없습니다.";
    }
    if (item.delYn === "Y") {
      render = "취소된주문"
    }
    return render;
  }

  const trnsprtDvyfgAm = () => {
    let amount = 0;
    ord.map((item) => {
      amount = amount + item.trnsprtDvyfgAm;
    })
    return amount;
  }

  const demandTot = () => {
    let amount = 0;
    ord.map((item) => {
      amount = amount + item.demandTot;
    })
    return amount;
  }

  const cancelAmount = () => {
    let amount = 0;
    if (payments.cancels !== null) {
      payments.cancels.map((item) => {
        amount = amount + item.cancelAmount;
      })
    }
    return amount;
  }

  const phoneNumber = (num) => {
    if (num.length === 11) {
      return num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
    return num;
  }

  const renderBank = () => {
    let render = "";
    switch (payments.method) {
      case "가상계좌":
        render = bankCode[`${payments.virtualAccount.bankCode}`];
        break;
      case "간편결제":
        render = payments.easyPay.provider;
        break;
      case "카드":
        render = cardCode[`${payments.card.acquirerCode}`];
        break;
      case "계좌이체":
        render = bankCode[`${payments.transfer.bankCode}`];
        break;
      case "핸드폰":
        render = "핸드폰";
        break;
      case "문화상품권":
        render = "문화상품권";
        break;
      case "도서문화상품권":
        render = "도서문화상품권";
        break;
      case "게임문화상품권":
        render = "게임문화상품권";
        break;
      default:
        render = "정보가없습니다.";
    }

    return render;
  }

  const delivery = (item) => {
    const width = "400";
    const height = "700";
    const left = Math.ceil((window.screen.width - width) / 2);
    const top = Math.ceil((window.screen.height - height) / 2);
    const code = "01";
    const invoice = 1;
    const url = "/trackingPopup.html" +
      "?code=" + encodeURIComponent(code) +
      "&invoice=" + encodeURIComponent(invoice);

    window.open(url, "배송조회", "width=" + width + ", height=" + height + ", left=" + left + ", top=" + top);
  }

  if (isLoading) {
    return (
      <Container>
        <Row>
          <Col>
            {isLoading && (
              <div
                style={{
                  height: "300px",
                }}
              >
                <Loading />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container className="py-5"
      style={{ marginTop: "5em", marginBottom: "5em" }}>
      <h2>
        주문상세
      </h2>
      <Row className="mb-5">
        <Col>
          {
            ord.map((item) => (
              <Card key={item.ordNo} style={{ marginBottom: "1rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ padding: "20px" }}>
                    <p style={{ textAlign: "left" }}>{renderDelyStatus(item)}</p>
                    {
                      item.dtlList.map((obj) => (
                        <div key={obj.ordDtlNo} style={{ display: "flex", marginBottom: "1rem" }}>
                          <img
                            onClick={() => {
                              handleClick(item, obj);
                            }}
                            style={{ width: 100, cursor: "pointer" }}
                            src={`https://cloud.1472.ai:18443/images/cloud/uploads/${obj.repImg}`}
                          />
                          <div style={{ display: "flex", flexDirection: "column", marginLeft: "1rem" }}>
                            <span>
                              {obj.prductNm}
                            </span>
                            <span style={{ textAlign: "left" }}>
                              {obj.cost.toLocaleString()} 원 &#183; {obj.qy}개
                            </span>
                            <span style={{ textAlign: "left" }}>
                              {obj.rtnSe && <span>교환 / 반품 진행중</span>}
                            </span>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", marginRight: "1rem" }}>
                    {
                      item.progrsSttus === "OWS014" ?
                        (<Button variant="primary"
                          style={{ backgroundColor: "#F39802", border: 0, marginBottom: "1rem" }}
                          onClick={() => {
                            delivery(item);
                          }}
                        >
                          배송조회
                        </Button>) : null
                    }
                    {
                      item.progrsSttus === "OWS012" ? (<Button variant="primary"
                        style={{
                          backgroundColor: "#F39802", border: 0, marginBottom: "1rem", whiteSpace: "nowrap",
                          display: item.ordSrcNo !== null || item.delYn === "Y" ? "none" : "block"
                        }}
                        onClick={() => {
                          if (item.delYn === "Y") {
                            errorToast("이미 취소된 주문입니다.");
                            return;
                          }
                          setCancelNo(item);
                          handleOpenModal();
                        }}>
                        주문취소</Button>) : null
                    }
                    {
                      item.progrsSttus === "OWS015" ? (<Button variant="primary"
                        style={{ backgroundColor: "#F39802", border: 0, marginBottom: "1rem", whiteSpace: "nowrap" }}
                        onClick={() => {
                          navigate("/order-cancel", {
                            state: { payments: payments, ord: item }
                          })
                        }}>
                        교환 환불</Button>) : null
                    }
                  </div>
                </div>
                {/* {test(item.ordNo)} */}
              </Card>
            ))
          }
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h4 className="text-left">
            주문정보
          </h4>
          <Card style={{ border: 0 }}>
            <Card.Body>
              <Table responsive className="text-center">
                <tbody>
                  <tr>
                    <td colSpan={1} style={{ width: "24%", whiteSpace: "nowrap" }}>주문일자</td>
                    <td colSpan={7} className="text-right">
                      {/* {orderTime} */}
                      {ord[0].dedtDe}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ width: "24%", whiteSpace: "nowrap" }}>이름</td>
                    <td colSpan={7} className="text-right">
                      {ord[0].rcpnt}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>번호</td>
                    <td colSpan={7} className="text-right">
                      {phoneNumber(ord[0].bcncTelno)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>배송지</td>
                    <td colSpan={7} className="text-right">
                      {ord[0].sptNm}
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
        {
          payments ? (
            <Col>
              <h4 className="text-left">
                결제정보
              </h4>
              <Table responsive className="text-center">
                <tbody>
                  <tr>
                    <td>
                      <div style={{ whiteSpace: "nowrap", display: "flex", flexDirection: "column", width: "20vw" }}>
                        <span>결제수단</span>
                        <strong> {payments.method} / {renderBank()}</strong>
                      </div>
                    </td>
                    <td style={{ width: "17rem" }}>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>총 상품가격</span>
                        <strong>{demandTot().toLocaleString()}원</strong>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>배송비</span>
                        <span>{trnsprtDvyfgAm().toLocaleString()}원</span>
                      </div>
                    </td>
                  </tr>
                  {
                    payments.cancels ? (
                      <tr>
                        <td></td>
                        <td>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>결제금액</span>
                            <strong>{(demandTot() + trnsprtDvyfgAm()).toLocaleString()}원</strong>
                          </div>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <span>취소금액</span>
                            <span> - {cancelAmount().toLocaleString()}원</span>
                          </div>

                        </td>
                      </tr>
                    ) : null
                  }
                  <tr>
                    <td></td>
                    <td>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>결제수단</span>
                        <span>{payments.method}</span>
                      </div>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>총 결제금액</span>
                        <strong>{(demandTot() + trnsprtDvyfgAm() - cancelAmount()).toLocaleString()}원</strong>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
              {/* <div className="form-group">
                <input type="text" className="form-control" name="t_code" id="t_code" placeholder="택배사 코드" />
              </div>
              <div className="form-group">
                <input type="text" className="form-control" name="t_invoice" id="t_invoice" placeholder="운송장 번호" />
              </div>
              <button className="btn btn-default" onClick={() => {
                delivery();
              }}>조회하기</button> */}
            </Col>
          ) : (null)
        }

      </Row>
      <Button
        variant="primary"
        style={{ backgroundColor: "#F39802", border: 0 }}
        onClick={() => {
          navigate("/my-page");
        }}
      >목록으로</Button>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} title="주문취소" name="common">
        <p>주문을 취소 하시겠습니까?</p>
        <Button variant='light'
          style={{ color: "#F39892", border: "1px solid #F39802" }}
          className='mr-2' onClick={handleCloseModal}
        >
          취소
        </Button>
        <Button variant='primary'
          className='mr-2'
          style={{ backgroundColor: "#F39802", border: 0 }}
          onClick={() => {
            paymentsCancel();
            handleCloseModal(true);
          }}
        >
          확인
        </Button>
      </Modal>
    </Container >
  );
};

export default OrderDetail;