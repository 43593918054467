import React, { useState, useEffect, forwardRef } from "react";
import Modal from '../common/Modal';
import { Container, Row, Col, Card, Form, Button, ListGroup } from 'react-bootstrap';
import { errorToast, successToast } from "../common/Toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import DaumPostcode from "react-daum-postcode";
import Terms1 from "./Terms1";
import Terms2 from "./Terms2";

const BizSignUp = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(true);
  const [checkboxes, setCheckboxes] = useState([false, false, false]);
  const [selectAll, setSelectAll] = useState(false);
  const [istPostModalOpen, setIsPostModalOpen] = useState(false);
  const [checkId, setCheckId] = useState(0);
  const [checkBizrno, setCheckBizrno] = useState(0);
  const [checkPhone, setCheckPhone] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const userState = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    if (userState.isLoggedIn) {
      navigate("/");
    }
  }, [userState, navigate]);


  // 약관동의 감지후 버튼 활성화
  useEffect(() => {
    if (checkboxes[0] && checkboxes[1]) {
      setActive1(false);
    } else {
      setActive1(true);
    }
  }, [checkboxes[0], checkboxes[1]])

  // 체크박스
  useEffect(() => {
    if (!checkboxes[0] || !checkboxes[1] || !checkboxes[2]) {
      setSelectAll(false);
    }
  }, [checkboxes[0], checkboxes[1], checkboxes[2]])

  // input 내용, 중복검사 확인후 버튼 활성화
  useEffect(() => {
    checkInputsValidity();
  }, [checkBizrno, checkId, checkPhone, userInfo])

  // 전체체크 체크박스
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    const updatedCheckboxes = checkboxes.map((checkbox) => {
      return selectAll ? false : true;
    });
    setCheckboxes(updatedCheckboxes);
  };

  // input 내용, 중복검사 확인후 버튼 활성화
  const checkInputsValidity = () => {
    if (
      userInfo.userName &&
      userInfo.password &&
      userInfo.confirmPassword &&
      userInfo.password === userInfo.confirmPassword &&
      userInfo.cmpnyNm &&
      userInfo.name &&
      userInfo.email &&
      userInfo.phone &&
      userInfo.bizrno &&
      checkBizrno === 2 &&
      checkId === 2 &&
      checkPhone === 2
    ) {
      setActive2(false);
    } else {
      setActive2(true);
    }
  }

  // 이전버튼
  const handlePrevious = () => {
    setActiveTab((prevTab) => Math.max(prevTab - 1, 1));
  };

  // 다음버튼
  const handleNext = () => {
    setActiveTab((prevTab) => Math.min(prevTab + 1, 3));
  };

  const handleClosePostModal = () => {
    setIsPostModalOpen(false);
  }

  // 주소 검색시 실행
  const handleComplete = (data) => {
    console.log(data)
    // Input 결과저장
    // setAddress(data.roadAddress);
    setUserInfo({ ...userInfo, address: data.roadAddress, zonecode: data.zonecode });
    // setZonecode(data.zonecode);
    setIsPostModalOpen(false)
  };


  const signup = (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append("cmpny.cmpnyNm", userInfo.cmpnyNm);
    formData.append("cmpny.rprNm", userInfo.name);
    formData.append("cmpny.taxbilEmail", userInfo.email);
    formData.append("cmpny.bizrno", userInfo.bizrno);
    formData.append("cmpny.zip", userInfo.zonecode);
    formData.append("cmpny.adres", userInfo.address);
    formData.append("cmpny.dtlAdres", userInfo.dtlAdres);
    formData.append("cmpny.useYn", "Y");
    formData.append("member.username", userInfo.userName);
    formData.append("member.password", userInfo.password);
    formData.append("member.rgsCl", "MBRS03");
    formData.append("member.moblphonNo", userInfo.phone);
    formData.append("member.email", userInfo.email);
    formData.append("member.name", userInfo.name);
    formData.append("member.delYn", "Y")

    console.log(formData.get("cmpny.cmpnyNm"));
    console.log(formData.get("cmpny.rprNm"));
    console.log(formData.get("cmpny.taxbilEmail"));
    console.log(formData.get("cmpny.useYn"));
    console.log(formData.get("cmpny.bizrno"));
    console.log(formData.get("cmpny.zip"));
    console.log(formData.get("cmpny.adres"));
    console.log(formData.get("cmpny.dtlAdres"));
    console.log(formData.get("member.username"));
    console.log(formData.get("member.password"));
    console.log(formData.get("member.rgsCl"));
    console.log(formData.get("member.email"));
    console.log(formData.get("member.name"));
    console.log(formData.get("member.moblphonNo"));
    console.log(formData.get("member.delYn"));
    mberSbscrb(formData);
  }

  // id 중복확인
  const isExistId = async () => {
    if (!userInfo.userName) {
      errorToast("아이디를 입력하세요.")
      return;
    }
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/auth/isExistId", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: userInfo.userName
        })
      });
      const data = await response.json();
      console.log(data);
      if (data.message === "isExist") {
        errorToast("이미 존재하는 아이디 입니다.")
        setCheckId(1);
      } else if (data.message === "isNotExist") {
        setCheckId(2);
        successToast("사용 가능한 아이디 입니다.")
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleIdChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z0-9]/g, '');

    setUserInfo({ ...userInfo, userName: value });
    setCheckId(0);
  }

  const handlePhoneChange = () => {
    setCheckPhone(0);
  }

  const handleBizrnoChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");

    if (value.length > 2 && value.length <= 5) {
      value = value.slice(0, 3) + "-" + value.slice(3);
    } else if (value.length > 5) {
      value = value.slice(0, 3) + "-" + value.slice(3, 5) + "-" + value.slice(5);
    }

    setUserInfo({ ...userInfo, bizrno: value });
    setCheckBizrno(0);
  }

  // 사업자 번호 확인
  const isExistBizrno = async () => {
    if (!userInfo.bizrno) {
      errorToast("사업자번호를 입력하세요");
      return;
    }
    const bizrno = userInfo.bizrno.replace(/-/g, '');

    if (bizrno !== "0000000000") {
      const valueMap = bizrno.replace(/-/gi, "").split("").map((item) => {
        return Number(item);
      });

      if (valueMap.length === 10) {
        const multiply = [1, 3, 7, 1, 3, 7, 1, 3, 5];
        let checkSum = 0;

        for (let i = 0; i < multiply.length; i++) {
          checkSum += multiply[i] * valueMap[i];
        }
        checkSum += Number(Math.floor((valueMap[8] * 5) / 10));

        if (valueMap[9] === (10 - checkSum % 10) % 10) {
          try {
            const response = await fetch(`https://cloud.1472.ai:18443/api/v2/auth/mberSbscrb/${userInfo.bizrno}`, {
              method: "POST",
              body: userInfo.bizrno
            });
            const data = await response.json();
            console.log(data);
            if (data.signUpPsblYn === "N" ||
              data.signUpPsblYn === "Y" && data.cmpnyNo !== null
            ) {
              console.log("N");
              setCheckBizrno(1);
            } else if (data.signUpPsblYn === "Y" && data.cmpnyNo === null) {
              console.log("Y");
              successToast("가입이 가능한 사업자번호입니다.");
              setCheckBizrno(2);
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          errorToast("잘못된 사업자등록번호 입니다.");
          setCheckBizrno(3);
        }
      } else {
        errorToast("사업자등록번호의 자리수를 확인해 주십시오.");
        setCheckBizrno(4);
      }
    } else {
      errorToast("해당사업자등록번호는 사용할 수 없습니다.");
      setCheckBizrno(5);
    }
  }

  const isExistPhone = async (phone) => {
    if (!phone) {
      errorToast("전화번호를 입력하세요.");
      return;
    }
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/mberMgmt/mberList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          moblphonNo: phone,
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: false,
        })
      });
      const data = await response.json();
      console.log(data);
      if (data.content.length !== 0) {
        errorToast("이미 존재하는 번호입니다.");
        setCheckPhone(1);
      } else {
        successToast("사용 가능한 번호입니다.");
        setCheckPhone(2);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // 회원가입 api
  const mberSbscrb = async (formData) => {
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/auth/mberSbscrb", {
        method: "POST",
        body: formData
      });
      const data = await response.json();
      console.log(data);
      if (data.success) {
        // mberSbscrb2(formData);
        setActiveTab(0);
      }
      // 성공여부로 페이지이동
    } catch (error) {
      console.error(error);
    }
  }
  // 개인 이름의 회사 회원가입 api
  const mberSbscrb2 = async (formData) => {
    formData.set("cmpny.cmpnyNm", userInfo.name);
    formData.delete("cmpny.bizrno");
    formData.delete("cmpny.zip");
    formData.delete("cmpny.adres");
    formData.delete("cmpny.dtlAdres");
    console.log(formData);
    console.log(formData.get("cmpny.bizrno"));
    console.log(formData.get("cmpny.cmpnyNm"));
    // return;
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/auth/mberSbscrb", {
        method: "POST",
        body: formData
      });
      const data = await response.json();
      console.log(data);
      if (data.success) {
        setActiveTab(0);
      }
      // 성공여부로 페이지이동
    } catch (error) {
      console.error(error);
    }
  }

  const renderCheckId = () => {
    if (checkId === 0) {
      return "중복검사를 해주세요";
    } else if (checkId === 1) {
      return "이미 존재하는 아이디 입니다.";
    } else if (checkId === 2) {
      return "사용가능한 아이디 입니다.";
    }
  }

  const renderCheckPhone = () => {
    if (checkPhone === 0) {
      return "중복검사를 해주세요";
    } else if (checkPhone === 1) {
      return "이미 사용중인 번호 입니다.";
    } else if (checkPhone === 2) {
      return "사용가능한 번호 입니다.";
    }
  }


  const renderCheckBizrno = () => {
    if (checkBizrno === 0) {
      return "중복검사를 해주세요.";
    } else if (checkBizrno === 1) {
      return "가입이 불가능한 사업자번호입니다.";
    } else if (checkBizrno === 2) {
      return "가입이 가능한 사업자번호입니다.";
    } else if (checkBizrno === 3) {
      return "잘못된 사업자등록번호 입니다.";
    } else if (checkBizrno === 4) {
      return "사업자등록번호의 자리수를 확인해 주십시오.";
    } else if (checkBizrno === 5) {
      return "해당사업자등록번호는 사용할 수 없습니다.";
    }
  }

  const renderCheckpassword = () => {
    if (userInfo.password !== userInfo.confirmPassword) {
      return "비밀번호가 일치하지 않습니다."
    }
  }

  // 이메일 확인
  const validEmailCheck = (email) => {
    let pattern = /^[A-Za-z0-9\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
    return !(email.match(pattern) != null);
  }

  // 핸드폰 번호 확인
  const validPhoneCheck = (moblphonNo) => {
    let pattern = /^\d{3}\d{3,4}\d{4}$/;
    moblphonNo = moblphonNo.replace(/-/g, "");
    return !(moblphonNo.match(pattern) != null);
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      isExistBizrno();
    }
    if (e.keyCode === 8) {
      let value = e.target.value;

      if (value.length > 3 && value[value.length - 1] === "-") {
        value = value.slice(0, value.length - 1);
      }

      setUserInfo({ ...userInfo, bizrno: value });
      setCheckBizrno(0);
    }
  }

  const handleKeyPress2 = (e) => {
    if (e.key === "Enter") {
      isExistId();
    }
  }

  return (
    <Container className="py-5" style={{ marginTop: "5em", marginBottom: '5em' }}>
      <h2>회원가입</h2>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center", marginBottom: "2em" }}>
          {activeTab === 1 && (
            <div>
              <Card style={{ marginTop: "2rem" }}>
                <Card.Header style={{ display: "flex", justifyContent: "space-between" }}>
                  <label htmlFor="check1">이층창고 서비스 이용약관 (필수)</label>
                  <input
                    type="checkbox"
                    id="check1"
                    checked={checkboxes[0]}
                    onChange={() => {
                      const updatedCheckboxes = [...checkboxes];
                      updatedCheckboxes[0] = !updatedCheckboxes[0];
                      setCheckboxes(updatedCheckboxes);
                    }}
                  />
                </Card.Header>
                <Card.Body style={{ height: "200px", textAlign: "left", overflowY: "scroll" }}>
                  <Terms1 />
                </Card.Body>
              </Card>
              <Card style={{ marginTop: "2rem" }}>
                <Card.Header style={{ display: "flex", justifyContent: "space-between" }}>
                  <label htmlFor="check2">이층창고 개인정보 처리방침(필수)</label>
                  <input
                    type="checkbox"
                    id="check2"
                    checked={checkboxes[1]}
                    onChange={() => {
                      const updatedCheckboxes = [...checkboxes];
                      updatedCheckboxes[1] = !updatedCheckboxes[1];
                      setCheckboxes(updatedCheckboxes);
                    }}
                  />
                </Card.Header>
                <Card.Body style={{ height: "200px", textAlign: "left", overflowY: "scroll" }}>
                  <Terms2 />
                </Card.Body>
              </Card>
              <div style={{ marginTop: "2rem", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <label htmlFor="check3">광고성 정보수신 (선택)</label>
                <input
                  style={{ marginLeft: "1rem" }}
                  id="check3"
                  type="checkbox"
                  checked={checkboxes[2]}
                  onChange={() => {
                    const updatedCheckboxes = [...checkboxes];
                    updatedCheckboxes[2] = !updatedCheckboxes[2];
                    setCheckboxes(updatedCheckboxes);
                  }}
                />
              </div>
              <div style={{ marginTop: "2rem", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <label htmlFor="check4">내용을 확인했으며 모든 약관에 동의합니다.</label>
                <input
                  style={{ marginLeft: "1rem" }}
                  id="check4"
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <Form style={{ textAlign: "left", width: "450px" }}>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>사업자 <span style={{ color: "red" }}>*</span></Form.Label>
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type='text'
                    value={userInfo.bizrno ?? ""}
                    onKeyDown={handleKeyPress}
                    onChange={handleBizrnoChange}
                    style={{ height: "43px", width: "80%" }}
                    placeholder='사업자번호 입력(10자리, 숫자만 입력)'
                    maxLength="12"
                  />
                  <Button
                    variant="primary"
                    style={{ backgroundColor: "#F39802", border: 0, width: "90px", whiteSpace: "nowrap" }}
                    onClick={() => {
                      isExistBizrno();
                    }}
                  > 중복확인</Button>
                </div>
                <span style={{ fontSize: "0.9rem", padding: "10px" }}>{renderCheckBizrno()}</span>
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>회사명 <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  type='text'
                  name="cmpnyNm"
                  onChange={(e) => {
                    setUserInfo({ ...userInfo, cmpnyNm: e.target.value });
                  }}
                  style={{ height: "43px" }}
                  placeholder='회사명 입력' />
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>아이디 <span style={{ color: "red" }}>*</span></Form.Label>
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type='text'
                    value={userInfo.userName ?? ""}
                    onKeyDown={handleKeyPress2}
                    onChange={handleIdChange}
                    style={{ height: "43px", width: "80%" }}
                    placeholder='아이디 입력' />
                  <Button
                    variant="primary"
                    style={{ backgroundColor: "#F39802", border: 0, width: "90px", whiteSpace: "nowrap" }}
                    onClick={() => {
                      isExistId();
                    }}
                  >중복확인</Button>
                </div>
                <span style={{ fontSize: "0.9rem", padding: "10px" }}>{renderCheckId()}</span>
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>비밀번호 <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  type='password'
                  onChange={(e) => {
                    setUserInfo({ ...userInfo, password: e.target.value });
                  }}
                  style={{ height: "43px" }}
                  placeholder='비밀번호 입력' />
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>비밀번호확인 <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  type='password'
                  onChange={(e) => {
                    setUserInfo({ ...userInfo, confirmPassword: e.target.value });
                  }}
                  style={{ height: "43px" }}
                  placeholder='비밀번호 확인' />
                <span style={{ fontSize: "0.9rem", padding: "10px" }}>{renderCheckpassword()}</span>
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>이름 <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e) => {
                    setUserInfo({ ...userInfo, name: e.target.value });
                  }}
                  name="name"
                  style={{ height: "43px" }}
                  placeholder='이름 입력' />
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>이메일 <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  type='text'
                  onChange={(e) => {
                    setUserInfo({ ...userInfo, email: e.target.value });
                  }}
                  name="email"
                  style={{ height: "43px" }}
                  placeholder='이메일 입력(ex. test@naver.com)' />
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>휴대폰 <span style={{ color: "red" }}>*</span></Form.Label>
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type='text'
                    onChange={(e) => {
                      handlePhoneChange();
                      setUserInfo({ ...userInfo, phone: e.target.value });
                    }}
                    name="moblphonNo"
                    style={{ height: "43px", width: "80%" }}
                    placeholder='휴대폰 번호 입력' />
                  <Button
                    variant="primary"
                    style={{ backgroundColor: "#F39802", border: 0, width: "90px", whiteSpace: "nowrap" }}
                    onClick={() => {
                      const moblphonNoValue = document.getElementsByName("moblphonNo")[0].value;
                      isExistPhone(moblphonNoValue);
                    }}
                  > 중복확인</Button>
                </div>
                <span style={{ fontSize: "0.9rem", padding: "10px" }}>{renderCheckPhone()}</span>
              </Form.Group>
              <Form.Label>전화번호</Form.Label>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Control
                  type='text'
                  name="telno"
                  onChange={(e) => {
                    setUserInfo({ ...userInfo, telno: e.target.value });
                  }}
                  style={{ height: "43px" }}
                  placeholder='전화번호 입력' />
              </Form.Group>
            </Form>
          )}
          {activeTab === 3 && (
            <Form style={{ textAlign: "left", width: "450px" }} onSubmit={signup}>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>우편번호 <span style={{ color: "red" }}>*</span></Form.Label>
                <div style={{ display: "flex" }}>
                  <Form.Control
                    type='text'
                    name="zip"
                    style={{ height: "43px", width: "80%" }}
                    readOnly
                    value={userInfo.zonecode ?? ""}
                    placeholder='우편번호' />
                  <Button
                    variant="primary"
                    style={{ backgroundColor: "#F39802", border: 0, width: "90px", whiteSpace: "nowrap" }}
                    onClick={() => {
                      setIsPostModalOpen(true);
                    }}
                  >주소검색</Button>
                </div>
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>주소 <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  type='text'
                  name="adres"
                  style={{ height: "43px" }}
                  value={userInfo.address ?? ""}
                  readOnly
                  placeholder='주소' />
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>상세주소</Form.Label>
                <Form.Control
                  type='text'
                  name="dtlAdres"
                  onChange={(e) => {
                    setUserInfo({ ...userInfo, dtlAdres: e.target.value })
                  }}
                  style={{ height: "43px" }}
                  placeholder='상세주소' />
              </Form.Group>
              <Button
                style={{ backgroundColor: "#F39802", width: "100%", border: 0, height: "50px", whiteSpace: "nowrap" }}
                type="submit">
                회원가입
              </Button>
            </Form>
          )}
          {activeTab === 0 && (
            <div className="card shadow" style={{ width: "350px", marginTop: "5rem" }}>
              <div className="card-body p-5">
                <h4 style={{ marginBottom: "3rem" }}>회원가입 성공!</h4>
                <Link to="/login" className="text-decoration-none">로그인 하러 가기</Link>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Button
        style={{ backgroundColor: "#F39802", border: 0, marginRight: "10px", display: activeTab === 0 ? "none" : "inline-block" }}
        onClick={() => handlePrevious()}
      >
        이전
      </Button>
      {
        activeTab === 1 && (
          <Button
            style={{ backgroundColor: "#F39802", border: 0, marginLeft: "10px" }}
            disabled={active1}
            onClick={() => handleNext()}
          >다음</Button>
        )
      }
      {
        activeTab === 2 && (
          <Button
            style={{ backgroundColor: "#F39802", border: 0, marginLeft: "10px" }}
            disabled={active2}
            onClick={() => {
              if (validEmailCheck(userInfo.email)) {
                errorToast("바르지 못한 이메일 형식입니다.");
                return;
              }
              if (validPhoneCheck(userInfo.phone)) {
                errorToast("바르지 못한 휴대폰번호 형식입니다.");
                return;
              }
              handleNext();
            }}
          >
            다음
          </Button>
        )
      }
      {/* 주소검색 */}
      <Modal isOpen={istPostModalOpen} onClose={handleClosePostModal} title="주소검색" name="common">
        <DaumPostcode onComplete={handleComplete} />
        <Button variant="light" className="mr-2" onClick={() => {
          handleClosePostModal()
        }} style={{ position: "absolute", top: 0, right: 0 }} >X</Button>
      </Modal>
    </Container >
  );
};

export default BizSignUp;