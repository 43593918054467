import React, { useState, useEffect, useRef } from "react";
import Modal from '../common/Modal';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { errorToast, successToast } from "../common/Toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Terms1 from "./Terms1";
import Terms2 from "./Terms2";

const SignUp = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [active1, setActive1] = useState(true);
  const [checkboxes, setCheckboxes] = useState([false, false, false]);
  const [selectAll, setSelectAll] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [checkExist, setCheckExist] = useState({ id: 0, phone: 0 });
  const userState = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    if (userState.isLoggedIn) {
      navigate("/");
    }
  }, [userState, navigate]);

  // 약관동의 감지후 버튼 활성화
  useEffect(() => {
    if (checkboxes[0] && checkboxes[1]) {
      setActive1(false);
    } else {
      setActive1(true);
    }
  }, [checkboxes[0], checkboxes[1]])

  // 체크박스
  useEffect(() => {
    if (!checkboxes[0] || !checkboxes[1] || !checkboxes[2]) {
      setSelectAll(false);
    }
  }, [checkboxes[0], checkboxes[1], checkboxes[2]])

  // 전체체크 체크박스
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    const updatedCheckboxes = checkboxes.map((checkbox) => {
      return selectAll ? false : true;
    });
    setCheckboxes(updatedCheckboxes);
  };

  // 이전버튼
  const handlePrevious = () => {
    setActiveTab((prevTab) => Math.max(prevTab - 1, 1));
  };

  // 다음버튼
  const handleNext = () => {
    setActiveTab((prevTab) => Math.min(prevTab + 1, 3));
  };

  const signup = (event) => {
    event.preventDefault();
    const formData = new FormData();
    const email = event.target.elements.email.value;
    const name = event.target.elements.name.value;
    const moblphonNo = event.target.elements.moblphonNo.value;

    if (checkExist.id === 0) {
      errorToast("아이디 중복검사를 해주세요.");
      return;
    }
    if (checkExist.id === 1) {
      errorToast("다른 아이디를 사용해 주세요.");
      return;
    }
    if (!userInfo.userName) {
      errorToast("아이디를 입력하세요.")
      return;
    }
    if (!userInfo.password || !userInfo.confirmPassword) {
      errorToast("비밀번호를 입력하세요.");
      return;
    }
    if (userInfo.password != userInfo.confirmPassword) {
      errorToast("비밀번호가 일치하지 않습니다.");
      return;
    }
    if (!name) {
      errorToast("이름을 입력하세요.");
      return;
    }
    if (!email) {
      errorToast("이메일을 입력하세요.");
      return;
    } else {
      if (validEmailCheck(email) == false) {
        errorToast("이메일이 올바르지 않습니다.")
        return;
      }
    }
    console.log(checkExist);
    if (checkExist.phone === 0) {
      errorToast("핸드폰 중복검사를 해주세요.");
      return;
    }
    if (checkExist.phone === 1) {
      errorToast("이미 사용중인 번호입니다.");
      return;
    }
    if (!moblphonNo) {
      errorToast("핸드폰 번호를 입력하세요.");
      return;
    } else {
      if (validPhoneCheck(moblphonNo) == false) {
        errorToast("핸드폰 번호가 올바르지 않습니다.")
        return;
      }
    }

    console.log("###");
    formData.append("cmpny.cmpnyNm", name);
    formData.append("cmpny.rprNm", name);
    formData.append("cmpny.taxbilEmail", email);
    formData.append("cmpny.useYn", "Y");
    formData.append("member.username", userInfo.userName);
    formData.append("member.password", userInfo.password);
    formData.append("member.rgsCl", "MBRS03");
    formData.append("member.moblphonNo", moblphonNo);
    formData.append("member.email", email);
    formData.append("member.name", name);
    formData.append("member.delYn", "Y")

    console.log(formData.get("cmpny.cmpnyNm"));
    console.log(formData.get("cmpny.rprNm"));
    console.log(formData.get("cmpny.taxbilEmail"));
    console.log(formData.get("cmpny.useYn"));
    console.log(formData.get("member.username"));
    console.log(formData.get("member.password"));
    console.log(formData.get("member.rgsCl"));
    console.log(formData.get("member.email"));
    console.log(formData.get("member.name"));
    console.log(formData.get("member.moblphonNo"));
    console.log(formData.get("member.delYn"));
    mberSbscrb(formData);
  }

  // id 중복확인
  const isExistId = async () => {
    if (!userInfo.userName) {
      errorToast("아이디를 입력하세요.")
      return;
    }
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/auth/isExistId", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: userInfo.userName
        })
      });
      const data = await response.json();
      console.log(data);
      if (data.message === "isExist") {
        errorToast("이미 존재하는 아이디 입니다.")
        setCheckExist((prev) => ({
          ...prev, id: 1
        }));
      } else if (data.message === "isNotExist") {
        successToast("사용 가능한 아이디 입니다.")
        setCheckExist((prev) => ({
          ...prev, id: 2
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const isExistPhone = async (phone) => {
    if (!phone) {
      errorToast("전화번호를 입력하세요.");
      return;
    }
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/mberMgmt/mberList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          moblphonNo: phone,
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: false,
        })
      });
      const data = await response.json();
      console.log(data);
      if (data.content.length !== 0) {
        errorToast("이미 존재하는 번호입니다.");
        setCheckExist((prev) => ({
          ...prev, phone: 1
        }))
      } else {
        successToast("사용 가능한 번호입니다.");
        setCheckExist((prev) => ({
          ...prev, phone: 2
        }))
      }
    } catch (error) {
      console.error(error);
    }

  }

  const handleIdChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z0-9]/g, '');

    setUserInfo({ ...userInfo, userName: value });
    setCheckExist((prev) => ({
      ...prev, id: 0
    }));
  }

  const handlePhoneChange = () => {
    setCheckExist((prev) => ({
      ...prev, phone: 0
    }))
  }

  // 회원가입 api
  const mberSbscrb = async (formData) => {
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/auth/mberSbscrb", {
        method: "POST",
        body: formData
      });
      const data = await response.json();
      console.log(data);
      if (data.success) {
        setActiveTab(0);
      }
      // 성공여부로 페이지이동
    } catch (error) {
      console.error(error);
    }
  }

  const renderCheckId = () => {
    if (checkExist.id === 0) {
      return "중복검사를 해주세요";
    } else if (checkExist.id === 1) {
      return "이미 존재하는 아이디 입니다.";
    } else if (checkExist.id === 2) {
      return "사용가능한 아이디 입니다.";
    }
  }

  const renderCheckPhone = () => {
    if (checkExist.phone === 0) {
      return "중복검사를 해주세요";
    } else if (checkExist.phone === 1) {
      return "이미 사용중인 번호 입니다.";
    } else if (checkExist.phone === 2) {
      return "사용가능한 번호 입니다.";
    }
  }

  // 이메일 확인
  const validEmailCheck = (email) => {
    let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return (email.match(pattern) != null);
  }

  // 핸드폰 번호 확인
  const validPhoneCheck = (moblphonNo) => {
    let pattern = /^(010)-?(\d{3,4})-?(\d{4})$/;
    return (moblphonNo.match(pattern) != null);
  }

  return (
    <Container className="py-5" style={{ marginTop: "5em", marginBottom: '5em' }}>
      <h2>회원가입</h2>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} style={{ display: "flex", justifyContent: "center", marginBottom: "2em" }}>
          {activeTab === 1 && (
            <div>
              <Card style={{ marginTop: "2rem" }}>
                <Card.Header style={{ display: "flex", justifyContent: "space-between" }}>
                  <label htmlFor="check1">이층창고 서비스 이용약관 (필수)</label>
                  <input
                    type="checkbox"
                    id="check1"
                    checked={checkboxes[0]}
                    onChange={() => {
                      const updatedCheckboxes = [...checkboxes];
                      updatedCheckboxes[0] = !updatedCheckboxes[0];
                      setCheckboxes(updatedCheckboxes);
                    }}
                  />
                </Card.Header>
                <Card.Body style={{ height: "200px", textAlign: "left", overflowY: "scroll" }}>
                  <Terms1 />
                </Card.Body>
              </Card>
              <Card style={{ marginTop: "2rem" }}>
                <Card.Header style={{ display: "flex", justifyContent: "space-between" }}>
                  <label htmlFor="check2">이층창고 개인정보 처리방침(필수)</label>
                  <input
                    type="checkbox"
                    id="check2"
                    checked={checkboxes[1]}
                    onChange={() => {
                      const updatedCheckboxes = [...checkboxes];
                      updatedCheckboxes[1] = !updatedCheckboxes[1];
                      setCheckboxes(updatedCheckboxes);
                    }}
                  />
                </Card.Header>
                <Card.Body style={{ height: "200px", textAlign: "left", overflowY: "scroll" }}>
                  <Terms2 />
                </Card.Body>
              </Card>
              <div style={{ marginTop: "2rem", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <label htmlFor="check3">광고성 정보수신 (선택)</label>
                <input
                  style={{ marginLeft: "1rem" }}
                  id="check3"
                  type="checkbox"
                  checked={checkboxes[2]}
                  onChange={() => {
                    const updatedCheckboxes = [...checkboxes];
                    updatedCheckboxes[2] = !updatedCheckboxes[2];
                    setCheckboxes(updatedCheckboxes);
                  }}
                />
              </div>
              <div style={{ marginTop: "2rem", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <label htmlFor="check4">내용을 확인했으며 모든 약관에 동의합니다.</label>
                <input
                  style={{ marginLeft: "1rem" }}
                  id="check4"
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <Form style={{ textAlign: "left", width: "450px" }} onSubmit={signup}>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>아이디 <span style={{ color: "red" }}>*</span></Form.Label>
                <div style={{ display: "flex" }}>
                  <Form.Control
                    name="userName"
                    value={userInfo.userName ?? ""}
                    onChange={handleIdChange}
                    type='text'
                    style={{ height: "43px", width: "80%" }}
                    placeholder='아이디 입력' />
                  <Button
                    variant="primary"
                    style={{ backgroundColor: "#F39802", border: 0, width: "90px", whiteSpace: "nowrap" }}
                    onClick={() => {
                      isExistId();
                    }}
                  >중복확인</Button>
                </div>
                <span style={{ fontSize: "0.9rem", padding: "10px" }}>{renderCheckId()}</span>
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>비밀번호 <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  type='password'
                  onChange={(e) => {
                    setUserInfo({ ...userInfo, password: e.target.value });
                  }}
                  style={{ height: "43px" }}
                  placeholder='비밀번호 입력' />
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>비밀번호확인 <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  type='password'
                  onChange={(e) => {
                    setUserInfo({ ...userInfo, confirmPassword: e.target.value });
                  }}
                  style={{ height: "43px" }}
                  placeholder='비밀번호 확인' />
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>이름 <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  name="name"
                  type='text'
                  style={{ height: "43px" }}
                  placeholder='이름 입력' />
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>이메일 <span style={{ color: "red" }}>*</span></Form.Label>
                <Form.Control
                  name="email"
                  type='text'
                  style={{ height: "43px" }}
                  placeholder='이메일 입력(ex. test@naver.com)' />
              </Form.Group>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Label>휴대폰 <span style={{ color: "red" }}>*</span></Form.Label>
                <div style={{ display: "flex" }}>
                  <Form.Control
                    name="moblphonNo"
                    type='text'
                    onChange={handlePhoneChange}
                    style={{ height: "43px", width: "80%" }}
                    placeholder='휴대폰 번호 입력' />
                  <Button
                    variant="primary"
                    style={{ backgroundColor: "#F39802", border: 0, width: "90px", whiteSpace: "nowrap" }}
                    onClick={() => {
                      const moblphonNoValue = document.getElementsByName("moblphonNo")[0].value;
                      isExistPhone(moblphonNoValue);
                    }}
                  >중복확인</Button>
                </div>
                <span style={{ fontSize: "0.9rem", padding: "10px" }}>{renderCheckPhone()}</span>
              </Form.Group>
              <Form.Label>전화번호</Form.Label>
              <Form.Group style={{ marginBottom: "1rem" }}>
                <Form.Control
                  name="telno"
                  type='text'
                  style={{ height: "43px" }}
                  placeholder='전화번호 입력' />
              </Form.Group>
              <Button
                style={{ backgroundColor: "#F39802", border: 0, width: "100%", height: "50px", whiteSpace: "nowrap" }}
                type="submit">
                동의하고 회원가입
              </Button>
            </Form>
          )}
          {activeTab === 0 && (
            <div className="card shadow" style={{ width: "350px", marginTop: "5rem" }}>
              <div className="card-body p-5">
                <h4 style={{ marginBottom: "3rem" }}>회원가입 성공!</h4>
                <Link to="/login" className="text-decoration-none">로그인 하러 가기</Link>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Button
        style={{ backgroundColor: "#F39802", border: 0, marginRight: "10px", display: activeTab === 0 ? "none" : "inline-block" }}
        onClick={() => handlePrevious()}
      >
        이전
      </Button>
      {
        activeTab === 1 && (
          <Button
            style={{ marginLeft: "10px", backgroundColor: "#F39802", border: 0, }}
            disabled={active1}
            onClick={() => handleNext()}
          >다음</Button>
        )
      }
    </Container >
  );
};

export default SignUp;