import { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../product/styles/productMap.css";
import { useNavigate } from "react-router-dom";

function ProductMap({ prd }) {
  const navigate = useNavigate();
  const [mx, setMx] = useState(1100);
  const [draggable, setDraggable] = useState(true);
  const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  const handleMxChange = (newMx) => {
    setMx(newMx);
  }

  useEffect(() => {
    const handleDocumentClick = (prd) => (e) => {
      const target = e.target;
      const clickBox = document.querySelector('.clickBox');
      if (clickBox && clickBox.contains(target)) {
        navigate("/company-pdlist", {
          state: { product: prd },
        })
      }
    };
    const clickHandler = handleDocumentClick(prd);
    document.addEventListener("click", clickHandler);

    return () => {
      document.removeEventListener("click", clickHandler);
    };
  }, [prd])

  useEffect(() => {
    const script = document.createElement("script");
    const SERVER_APP_KEY = "dfb8211e9a5553f8050c65582853a961";
    const LOCAL_APP_KEY = "8400068f9c2268cfa77c552875a9ac80";
    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${SERVER_APP_KEY}&autoload=false&libraries=services`;
    script.onload = () => {
      window.kakao.maps.load(() => {
        const { kakao } = window;
        const container = document.getElementById("map");
        const geocoder = new window.kakao.maps.services.Geocoder();
        geocoder.addressSearch(
          `${prd.adres || '둔산남로 176'}`,
          (result, status) => {
            if (status === window.kakao.maps.services.Status.OK && result.length > 0) {
              const options = {
                center: new window.kakao.maps.LatLng(result[0].y, result[0].x),
                level: 3,
              };
              const map = new window.kakao.maps.Map(container, options);
              const zoomControl = new kakao.maps.ZoomControl();
              map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

              const closeUp = document.getElementById("closeUpBtn");
              closeUp?.addEventListener("click", async function () {
                const user = navigator.userAgent;
                let isCheck = false;
                if (user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1) {
                  isCheck = true;
                }
                let newMx = 2500;
                const map = document.getElementById("map");
                if (map.style.height === "400px") {
                  map.style.height = isCheck ? (screenHeight - 210) + "px" : "900px"
                  map.style.width = "100%";
                  newMx = 2500;
                }
                await handleMxChange(newMx);
                centerLayout();
              });

              const closeUp2 = document.getElementById("closeUpBtn2");
              closeUp2.addEventListener("click", async function () {
                let newMx = 1100;
                const map = document.getElementById("map");
                if (map.style.height !== "400px") {
                  map.style.height = "400px";
                  map.style.width = "100%";
                }
                await handleMxChange(newMx);
                centerLayout();
              })

              const centerLayout = () => {
                map.relayout();
                map.setCenter(new kakao.maps.LatLng(result[0].y, result[0].x));
              }
              const markerPosition = new window.kakao.maps.LatLng(
                result[0].y,
                result[0].x
              );

              let phone;
              if (!prd.telNo) {
                phone = "번호없음";
              } else {
                phone = prd.telNo;
                if (phone.length === 11) {
                  phone = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
                } else if (phone.length === 9) {
                  phone = phone.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
                } else if (phone.length === 10) {
                  phone = phone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
                } else if (phone.includes("-")) {
                  phone = phone;
                } else {
                  phone = "올바른 형식의 번호가 아닙니다.";
                }
              }

              const link = window.location.href;
              const origin = window.location.origin
              const url = link.replace(origin, "");
              let yAnchor = 1.60;
              let content =
                `<div class="overlaybox">` +
                `    <div class="boxtitle">${prd.cmpnyNm}</div>` +
                "    <ul>" +
                '        <li>' +
                '            <span class="number"></span>' +
                `            <span class="title">${prd.adres}</span>` +
                '        </li>' +
                '        <li>' +
                '            <span class="number"></span>' +
                `            <span class="title">${phone}</span>` +
                '        </li>';

              if (url === "/company-pdlist") {
                content = content +
                  "   </ul>" +
                  '</div>';
              } else {
                content = content +
                  '        <li>' +
                  '            <span class="title clickBox">판매자 제품 모두보기</span>' +
                  '        </li>' +
                  "   </ul>" +
                  '</div>';
                yAnchor = 1.5;
              }

              const customOverlay = new window.kakao.maps.CustomOverlay({
                position: markerPosition,
                content: content,
                xAnchor: 0.5,
                yAnchor: yAnchor,
              });

              const markerImage = new kakao.maps.MarkerImage(
                "https://1472.ai/img/marker.png",
                new kakao.maps.Size(40, 40),
                {
                  spriteOrigin: new kakao.maps.Point(0, 0),
                  spriteSize: new kakao.maps.Size(40, 40),
                }
              );

              const marker = new window.kakao.maps.Marker({
                position: new window.kakao.maps.LatLng(
                  result[0].y,
                  result[0].x
                ),
                image: markerImage,
              });

              marker.setMap(map);

              customOverlay.setMap(map);

              kakao.maps.event.addListener(marker, "click", function () {
                navigate("/company-pdlist", {
                  state: { product: prd },
                })
              })

            } else {
              const options = {
                center: new window.kakao.maps.LatLng('36.3487787978337', '127.396961564889'),
                level: 3,
              };
              const map = new window.kakao.maps.Map(container, options);

              const closeUp = document.getElementById("closeUpBtn");
              closeUp?.addEventListener("click", async function () {
                const user = navigator.userAgent;
                let isCheck = false;
                if (user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1) {
                  isCheck = true;
                }
                let newMx = 2500;
                const map = document.getElementById("map");
                console.log(map.style.height);
                if (map.style.height === "400px") {
                  map.style.height = isCheck ? (screenHeight - 210) + "px" : "900px"
                  map.style.width = "100%";
                }
                await handleMxChange(newMx);
                centerLayout();
              });

              const closeUp2 = document.getElementById("closeUpBtn2");
              closeUp2.addEventListener("click", async function () {
                let newMx = 1100;
                const map = document.getElementById("map");
                if (map.style.height !== "400px") {
                  map.style.height = "400px";
                  map.style.width = "100%";
                }
                await handleMxChange(newMx);
                centerLayout();
              })

              const centerLayout = () => {
                map.relayout();
                map.setCenter(new kakao.maps.LatLng('36.3487787978337', '127.396961564889'));
              }
              const markerPosition = new window.kakao.maps.LatLng(
                '36.3487787978337', '127.396961564889'
              );
              let phone = "";
              if (!prd.telNo) {
                phone = "번호없음";
              } else {
                phone = prd.telNo;
              }
              const content =
                `<div class="overlaybox">` +
                `    <div class="boxtitle">${prd.cmpnyNm}(지도 표시불가 사업장)</div>` +
                '    <div class="">' +
                "    </div>" +
                "    <ul>" +
                '        <li>' +
                '            <span class="number"></span>' +
                `            <span class="title">${prd.adres}</span>` +
                '        </li>' +
                '        <li>' +
                '            <span class="number"></span>' +
                `            <span class="title">${phone}</span>` +
                '        </li>' +
                "   </ul>" +
                '</div>';

              const customOverlay = new window.kakao.maps.CustomOverlay({
                position: markerPosition,
                content: content,
                xAnchor: 0.5,
                yAnchor: 1.55,
              });

              const markerImage = new kakao.maps.MarkerImage(
                // "/newMarker2.png",
                "https://1472.ai/img/marker.png",
                new kakao.maps.Size(40, 40),
                {
                  spriteOrigin: new kakao.maps.Point(0, 0),
                  spriteSize: new kakao.maps.Size(40, 40),
                }
              );

              const marker = new window.kakao.maps.Marker({
                position: new window.kakao.maps.LatLng(
                  '36.3487787978337', '127.396961564889'
                ),
                image: markerImage,
              });
              marker.setMap(map);

              customOverlay.setMap(map);
            }
          }
        );
      });
    };
    document.head.appendChild(script);
  }, []);

  return (
    <Container
      style={{ marginBottom: "60px", marginTop: "5em", maxWidth: `${mx}px` }}>
      <Row>
        <Col>
          <div onClick={() => { setDraggable(false) }} id="closeUpBtn" style={{ width: "100%", height: "400px", position: "absolute", zIndex: draggable ? 2 : -10 }}></div>
          <div id="map" style={{ width: "100%", height: "400px" }}></div>
          <div style={{ position: "absolute", zIndex: 8889, bottom: 0, right: 15 }}>
            <Button size="sm"
              onClick={() => {
                setDraggable(true);
              }}
              style={{ backgroundColor: "#F39802", border: 0, display: draggable ? "none" : "block", padding: "10px 20px", fontSize: "0.9rem" }}
              variant="primary" id="closeUpBtn2">
              축소
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ProductMap;
