import React from 'react';
import { Container } from 'react-bootstrap';
import Terms1 from '../member/Terms1';

const TermsOfService = () => {
	return (
		<Container style={{ marginTop: "12rem", marginBottom: "12rem" }}>
			<h2 style={{ marginBottom: "3rem" }}>이층창고 서비스 이용약관</h2>
			<Terms1 />
		</Container>
	);
};

export default TermsOfService;