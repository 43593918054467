import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Form, FormControl } from "react-bootstrap";
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import {
  FaShoppingCart,
  FaClock,
  FaSearch
} from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs'
import { errorToast, successToast } from '../common/Toast';
import { SlArrowRight } from "react-icons/sl";

function MyPage() {
  const isLoggedIn = useSelector(state => state.isLoggedIn);
  const userInfo = useSelector(state => state.user) ?? [];

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ordList, setOrdList] = useState([]);
  const [optionList, setOptionList] = useState([]);
  // const secretKey = 'test_sk_OEP59LybZ8Bdy9Q1kRWV6GYo7pRe:'
  const [radioValue, setRadioValue] = useState('1');

  const radios = [
    { value: "1", name: "오늘" },
    { value: "2", name: "일주일" },
    { value: "3", name: "한달" },
  ];

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    } else {
      getOrdList();
    }
  }, []);

  const ordSort = (ord) => {
    const groupedArr = ord.reduce((acc, obj) => {
      if (obj.ordId !== null) {
        const existingGroup = acc.find(group => group[0]?.ordId === obj.ordId);
        if (existingGroup) {
          existingGroup.push(obj);
        } else {
          acc.push([obj]);
        }
      } else {
        acc.push(obj);
      }
      return acc;
    }, []);

    return groupedArr;
  };

  const setDate = (type) => {
    const search = document.getElementById("searchText").value;
    switch (type) {
      case "오늘":
        searchOrd(search, dayjs().format("YYYY-MM-DD"));
        break;
      case "일주일":
        searchOrd(search, dayjs().subtract(1, "week").format('YYYY-MM-DD'));
        break;
      case "한달":
        searchOrd(search, dayjs().subtract(1, "month").format('YYYY-MM-DD'));
        break;
      default:
        console.error("Invalid date type");
        return;
    }
  }

  const addCart = async (item) => {
    let newList = [];
    if (!item.length) {
      newList.push(item);
    } else {
      newList = item;
    }

    try {
      newList.map(async (arr) => {
        arr.dtlList.map(async (obj) => {
          const response = await fetch("https://erp.1472.ai:28443/api/v1/baksetUpdate", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              delYn: "N",
              gubun: "E",
              imgUrl: obj.repImg,
              mberNo: userInfo.mberNo,
              ordCmpnyNm: userInfo.cmpnyNm,
              ordCmpnyNo: userInfo.cmpnyNo,
              prdNm: obj.prductNm,
              prdNo: obj.prdNo,
              prdPrice: obj.cost,
              qty: 1,
              bcncCmpnyNm: arr.bcncNm,
              bcncCmpnyNo: arr.bcncNo,
            })
          });
          const data = await response.json();
          console.log(data);
        })
      })
    } catch (error) {
      console.error(error);
    }
    successToast("장바구니에 상품이 추가되었습니다.");
  }

  // 결제취소
  // const paymentsCancel = async (paymentKey) => {
  //   try {
  //     const response = await fetch(`https://api.tosspayments.com/v1/payments/${paymentKey}/cancel`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Basic " + btoa(secretKey),
  //       },
  //       body: JSON.stringify({
  //         cancelReason: "삭제테스트"
  //       }),
  //     });
  //     const data = await response.json();
  //     console.log("결제취소");
  //     console.log(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // 결제정보
  // const getPayments = async (orderId) => {
  //   try {
  //     const response = await fetch(`https://api.tosspayments.com/v1/payments/orders/${orderId}`, {
  //       method: "GET",
  //       headers: {
  //         "Authorization": "Basic " + btoa(secretKey)
  //       }
  //     });
  //     const data = await response.json();
  //     console.log(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  const getOrdList = async () => {
    try {
      const response = await fetch("https://erp.1472.ai:28443/api/v1/ordInqry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ordMberNo: userInfo.mberNo,
          // ordCours: "OCR002",
          dedtDe: dayjs().format("YYYY-MM-DD"),
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: false
        })
      });
      const data = await response.json();
      const dummy = data.content;
      const newListPromises = dummy.map((item) => getDtlList(item));
      const newList = await Promise.all(newListPromises);
      const prdListFiltered = newList.filter((item) => item.ordId !== null);
      setOrdList(ordSort(prdListFiltered));

      prdListFiltered.map((arr) => {
        arr.dtlList.map((obj) => {
          if (obj.prdNo.includes("_")) {
            getOption(obj);
          }
        })
      })
    } catch (error) {
      console.error(error);
    }
  }

  const searchOrd = async (search, date) => {
    try {
      const response = await fetch("https://erp.1472.ai:28443/api/v1/ordInqry", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ordMberNo: userInfo.mberNo,
          ordCours: "OCR002",
          dedtDe: date,
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: false,
        }),
      });
      const data = await response.json();
      const dummy = data.content;
      const newListPromises = dummy.map((item) => getDtlList2(item, search));
      const newList = await Promise.all(newListPromises);
      const prdListFiltered = newList.filter((item) => item.ordId !== null && item.dtlList.length !== 0);
      setOrdList(ordSort(prdListFiltered));

      prdListFiltered.map((arr) => {
        arr.dtlList.map((obj) => {
          if (obj.prdNo.includes("_")) {
            getOption(obj);
          }
        })
      })
    } catch (error) {
      console.error(error);
    }
  }

  const getDtlList = async (item) => {
    return fetch("https://erp.1472.ai:28443/api/v1/ordDtlList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ordNo: item.ordNo,
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: true
      })
    })
      .then((response) => response.json())
      .then((data) => {
        const dtlList = data.content;
        return { ...item, dtlList };
      })
  }

  const getDtlList2 = async (item, search) => {
    return fetch("https://erp.1472.ai:28443/api/v1/ordDtlList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ordNo: item.ordNo,
        prductNm: search,
        offset: 0,
        pageNumber: 0,
        pageSize: 10,
        paged: true
      })
    })
      .then((response) => response.json())
      .then((data) => {
        const dtlList = data.content;
        return { ...item, dtlList };
      })
  }

  // 주문취소
  // const cancleOrd = async (item) => {
  //   let ordNo = [];
  //   if (item.length > 0) {
  //     item.map((obj) => {
  //       ordNo.push(obj.ordNo);
  //     })
  //   } else {
  //     ordNo.push(item.ordNo);
  //   }
  //   ordNo.map((ordNo) => {
  //     fetch("https://erp.1472.ai:28443/api/v1/cancelOrd", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify([{
  //         ordNo: ordNo,
  //         ordMberNo: userInfo.mberNo
  //       }]),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log(data);
  //         if (data.success) {
  //           getOrdList();
  //         }
  //       })
  //       .catch((error) => console.error(error));
  //   })
  // }

  const renderStatus = (item) => {
    let newItem = [];
    if (!item.length) {
      newItem.push(item);
    } else {
      newItem = item;
    }
    const checkDelYn = newItem.some(obj => obj.delYn === "Y");
    const checkProgrs = newItem.some(obj => obj.progrsSttus === "OWS011");

    if (checkDelYn) {
      return (
        <div style={{
          whiteSpace: "nowrap", margin: "8px", cursor: "pointer"
          , display: "flex", alignItems: "center"
        }} onClick={() => {
          clickCard(newItem);
        }}>
          <p
            style={{ margin: 0 }}
          >
            주문 상세보기 </p>
          <SlArrowRight />
        </div>
      )
    }

    if (checkProgrs) {
      return (
        <Button
          variant="primary"
          style={{ backgroundColor: "#F39802", border: 0 }}
          onClick={() => {
            clickCard(newItem);
          }}
        >결제이동</Button>
      )
    } else {
      return (
        <div style={{
          whiteSpace: "nowrap", margin: "8px", cursor: "pointer"
          , display: "flex", alignItems: "center"
        }} onClick={() => {
          clickCard(newItem);
        }}>
          <p
            style={{ margin: 0 }}
          >
            주문 상세보기 </p>
          <SlArrowRight />
        </div>
      )
    }
  }

  const renderDelyStatus = (item) => {
    let render = "";
    switch (item.progrsSttus) {
      case "OWS011":
        render = "입금전";
        if (item.ordSrcNo !== null) {
          render = "배송준비중";
        }
        break;
      case "OWS012":
        render = "입금완료";
        if (item.ordSrcNo !== null) {
          render = "배송준비중";
        }
        break;
      case "OWS013":
        render = "출고준비";
        if (item.ordSrcNo !== null) {
          render = "배송준비중";
        }
        break;
      case "OWS014":
        render = "배송중";
        break;
      case "OWS015":
        render = "배송완료";
        break;
      default:
        render = "정보가없습니다.";
    }
    if (item.delYn === "Y") {
      render = "취소된주문"
    }
    return render;
  }

  const clickCard = (item) => {
    let orderNum = [];
    let status = "";
    let delYn = "";
    if (item.length > 0) {
      status = item[0].progrsSttus
      delYn = item[0].delYn;
      item.map((obj) => {
        orderNum.push(obj.ordNo);
      })
    } else {
      status = item.progrsSttus
      delYn = item.delYn;
      orderNum.push(item.ordNo);
    }
    if (delYn === "Y") {
      navigate("/order-detail", {
        state: { orderNum: orderNum }
      })
      return;
    }
    if (status === "OWS011") {
      navigate("/order", {
        state: { orderNum: orderNum }
      })
    } else if (status === "OWS012") {
      navigate("/order-detail", {
        state: { orderNum: orderNum }
      })
    } else if (status === "OWS013") {
    } else if (status === "OWS014") {
    } else if (status === "OWS015") {
    } else {
      return "정보가 없습니다.";
    }
  }

  const handleClick = async (item, dtl) => {
    let product;
    const prdNo = dtl.prdNo.split("_")[0];
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prdNo: prdNo,
          cmpnyNo: item.bcncNo,
          echanggoYn: "Y",
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        })
      })
      const data = await response.json();
      console.log(data);
      product = data.content[0];
    } catch (error) {
      console.error(error);
    }
    navigate("/product-detail", {
      state: { product: product }
    })
  }

  const ordSearch = (event) => {
    event.preventDefault();
    const radio = event.target.radio;
    let type = "";
    for (let i = 0; i < radio.length; i++) {
      if (radio[i].checked) {
        type = radio[i].textContent;
      }
    }
    setDate(type);
  }

  const getOption = async (item) => {
    const prdNo = item.prdNo.split("_")[0];
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v1/prdOptNew", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prdNo: prdNo,
          delYn: "N",
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        })
      });
      const data = await response.json();
      setOptionList(data.content);
    } catch (error) {
      console.error(error);
    }
  }

  const renderOption = (item) => {
    if (item.prdNo.includes("_")) {
      const optNo = item.prdNo.split("_")[1];
      let optNm = "";
      optionList.map((obj) => {
        if (obj.optNo == optNo) {
          optNm = obj.optNm + " / ";
        }
      })
      return optNm;
    }
  }

  const renderOrdNo = (item) => {
    let newOrdNo = [];
    let ordNo = "";
    item.map((obj) => {
      newOrdNo.push(obj.ordNo);
    })
    newOrdNo.sort((a, b) => a - b);
    newOrdNo.map((obj) => {
      ordNo = ordNo + obj + ",";
    })
    ordNo = ordNo.substring(0, ordNo.length - 1);

    return ordNo;
  }

  const formattedNumber = userInfo.length === 0 ? "" : userInfo.moblphonNo.includes('-') ? userInfo.moblphonNo : userInfo.moblphonNo.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');

  return (
    <Container className="py-5" style={{ marginTop: "5em", marginBottom: '5em' }}>
      <Row>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Header>
              {userInfo.name}님, 안녕하세요!
            </Card.Header>
            <Card.Body>
              <p>이메일: {userInfo.email}</p>
              <p>연락처: {formattedNumber}</p>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button variant="primary" style={{ backgroundColor: "#F39802", border: 0 }}>
                  <Link to="/update-userinfo" style={{ textDecoration: "none", color: "white" }}>
                    정보 수정
                  </Link>
                </Button>
                <Button
                  variant="primary"
                  style={{ backgroundColor: "#F39802", border: 0 }}
                  onClick={() => {
                    dispatch({ type: "LOGOUT" });
                  }}
                >
                  로그아웃
                </Button>
              </div>
            </Card.Body>
          </Card>
          {/* <Card className="mb-4">
            <Card.Header>
              <FaTicketAlt /> 나의 쿠폰
            </Card.Header>
            <Card.Body>
              <p>적립금: 10,000원</p>
              <Button variant="primary">쿠폰 확인</Button>
            </Card.Body>
          </Card> */}
        </Col>

        <Col md={8}>
          <Form style={{ marginBottom: "20px" }} onSubmit={ordSearch}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
              <div>
                <ButtonGroup>
                  {radios.map((radio, idx) => (
                    <Button
                      key={idx}
                      id={`radio-${idx}`}
                      type="button"
                      style={{
                        backgroundColor: radioValue === radio.value ? "#F39802" : "white",
                        border: radioValue === radio.value ? 0 : "1px solid #F39802",
                        color: radioValue === radio.value ? "white" : "#F39802",
                        whiteSpace: "nowrap",
                        padding: "5px"
                      }}
                      name="radio"
                      value={radio.value}
                      checked={radioValue === radio.value}
                      onClick={(e) => {
                        setRadioValue(e.currentTarget.value);
                        setDate(e.currentTarget.textContent);
                      }}
                    >
                      {radio.name}
                    </Button>
                  ))}
                </ButtonGroup>
              </div>
              <div style={{ flexGrow: 1 }}></div>
              <div className="position-relative">
                <div style={{ textAlign: "left" }}>주문 검색</div>
                <FormControl
                  type="search"
                  name="ordSearch"
                  id="searchText"
                  placeholder="검색"
                  aria-label="Search"
                  style={{ width: "200px", paddingRight: "40px" }}
                />
                <Button
                  variant="link"
                  type="submit"
                  className="position-absolute"
                  style={{
                    top: "70%",
                    right: "0px",
                    transform: "translateY(-50%)",
                    color: "#F39802"
                  }}
                >
                  <FaSearch />
                </Button>
              </div>
            </div>
          </Form>
          <Card className="mb-4">
            <Card.Header>
              <FaShoppingCart /> 주문 내역
            </Card.Header>
            {
              ordList.length > 0 ?
                (
                  <>
                    <Card.Body>
                      {
                        ordList.map((item) => {
                          return (
                            <Card className='mb-4' key={item[0].ordNo}>
                              <Card.Header as="h6">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                  <p className='m-0'><span style={{ whiteSpace: "nowrap" }}>{item[0].dedtDe ?? ""}</span></p>
                                  <div style={{ flexGrow: 1 }}></div>
                                  {
                                    renderStatus(item)
                                  }
                                </div>
                              </Card.Header>
                              <Card.Body>
                                <ul className='list-group'>
                                  {
                                    item.map((arr) => (
                                      <Card key={arr.ordNo} style={{ marginBottom: "1rem" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                          <div></div>
                                          <div style={{ flexGrow: 1 }}></div>
                                        </div>
                                        <Card.Body>
                                          <ul className='list-group'>
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                              <p style={{ color: "rgb(153, 153, 153)" }}>{arr.bcncNm}</p>
                                              {
                                                renderDelyStatus(arr)
                                              }
                                            </div>
                                            {
                                              arr.dtlList.map((dtl) => (
                                                <div key={dtl.ordDtlNo} className='d-flex mb-2 mt-2 justify-content-between'>
                                                  <div style={{ cursor: "pointer" }}>
                                                    <img onClick={() => {
                                                      handleClick(arr, dtl);
                                                    }}
                                                      style={{ width: "80px" }} src={`https://cloud.1472.ai:18443/images/cloud/uploads/${dtl.repImg}`} />
                                                  </div>
                                                  <div style={{ textAlign: "right", marginLeft: "10px" }}>
                                                    <p style={{ cursor: "pointer" }} onClick={() => {
                                                      handleClick(arr, dtl);
                                                    }}>
                                                      {dtl.prductNm}</p>
                                                    <p>{renderOption(dtl)} {(dtl.cost).toLocaleString()}원 &#183;  <span>{dtl.qy}개</span></p>
                                                  </div>
                                                </div>
                                              ))
                                            }
                                          </ul>
                                        </Card.Body>
                                      </Card>
                                    ))
                                  }
                                </ul>
                              </Card.Body>
                              <Card.Footer>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                  <p className='m-0'>주문번호 : {renderOrdNo(item)}</p>
                                  <Button
                                    style={{ backgroundColor: "#F39802", border: 0, whiteSpace: "nowrap" }}
                                    onClick={() => {
                                      addCart(item);
                                    }}
                                  >장바구니 담기</Button>
                                </div>
                              </Card.Footer>
                            </Card>
                          )
                        })
                      }
                    </Card.Body>
                    {/* <Button
                      style={{ backgroundColor: "#F39802", border: 0 }}
                      onClick={() => {
                        getOrdList(pageSize + 5);
                      }}>더보기</Button> */}
                  </>
                ) : (
                  <span style={{ padding: "15px" }}>주문내역이 없습니다.</span>
                )
            }
          </Card>
          {/* <Card className="mb-4">
            <Card.Header>
              <FaEye /> 최근 본 상품
            </Card.Header>
            <Card.Body>
              <ul className="list-group" style={{ marginBottom: '1em' }}>
                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <p>상품명: MacBook Pro 2022</p>
                    <p>가격: 2,500,000원</p>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="d-flex justify-content-between">
                    <p>상품명: iPad Pro 2022</p>
                    <p>가격: 1,200,000원</p>
                  </div>
                </li>
              </ul>
              <Button variant="primary">최근 본 상품 모두 보기</Button>
            </Card.Body>
          </Card> */}
        </Col>
      </Row>
    </Container >
  );
}

export default MyPage;
