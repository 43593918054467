import { SlArrowRight } from "react-icons/sl"


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <SlArrowRight
      className={className}
      style={{ ...style, display: "block", color: "black", marginRight: "9px" }}
      onClick={onClick}
    />
  );
}

export default SampleNextArrow;