import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


const PortfolioSlider = ({ portfolio }) => {
  const navigate = useNavigate();
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    if (portfolio.length < 3) {
      setSlidesToShow(portfolio.length);
    }
  }, []);

  // 슬라이드 세팅
  const settings = {
    className: "center",
    arrow: true,
    infinite: true,
    slidesToShow: slidesToShow,
    speed: 400,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <div style={{ textAlign: "left", fontWeight: 800, margin: "1rem 0", fontSize: "1.4rem" }}>
        <span>포트폴리오</span>
      </div>
      <Slider {...settings}>
        {
          portfolio.map((item) => (
            <div key={item.portNo}>
              <div style={{ margin: "0 20px" }}
                onClick={() => {
                  navigate("/portfolio-detail", {
                    state: {
                      portfolio: item,
                      cmpnyNo: item.cmpnyNo
                    }
                  })
                  window.location.reload();
                }}>
                <Card>
		  {/*<Card.Img src={`https://cloud.1472.ai:18443${item.imgUrl}`} style={{ objectFit: "contain", height: "20vh" }} />*/}
		  <Card.Img src={item.imgUrl.includes("images") ? `https://cloud.1472.ai:18443${item.imgUrl}` : `https://cloud.1472.ai:18443/images/${item.imgUrl}`} style={{ objectFit: "Contain", height: "20vh" }} />
                </Card>
                <div style={{ textAlign: "left" }}>
                  <span className="badge badge-primary" style={{ backgroundColor: "#F39802" }}>{item.cmpnyNm}</span>
                  <div style={{ fontWeight: "600" }}>{item.title}</div>
                </div>
              </div>
            </div>
          ))
        }
      </Slider>
    </div>
  );
};

export default PortfolioSlider;
