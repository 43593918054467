import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Pagination, Row } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductMap from '../map/ProductMap';
import Loading from '../common/Loading';
import PortfolioSlider from "../common/PortfolioSlider";
import { useTitle } from '../common/UseTitle';

const CompanyProductList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const product = location.state?.product || "";
  const [portfolio, setPortfolio] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [pageInfo, setPageInfo] = useState({ page: 1, endPage: 0 })
  const changeTitle = useTitle();

  useEffect(() => {
    getProduct();
    getPortfolio();
    changeTitle(`${product.cmpnyNm}`);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    if (pageInfo.page === pageInfo.endPage) {
      return;
    }
    if (productList.length === 0) {
      return;
    }
    if (scrollTop + clientHeight >= scrollHeight && fetching === false) {
      moreData(pageInfo.page);
      setPageInfo((prev) => ({
        ...prev,
        page: pageInfo.page + 1
      }));
    }
  };

  const getProduct = async () => {
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cmpnyNo: product.cmpnyNo,
          echanggoYn: "Y",
          offset: 0,
          pageNumber: 0,
          pageSize: 40,
          paged: true,
        })
      });
      const prdList = await response.json();
      const prdListFiltered = prdList.content
        .filter((item) => item.prdImgFlpth !== null)
        .map((item) => {
          if (item.notitlePdCmPrice === null) {
            item.notitlePdCmPrice = 0;
          }
          console.log(item);
          if(!item.extrlImgUrl.includes("http")){
            item.extrlImgUrl = `https://cloud.1472.ai:18443/images/${item.extrlImgUrl}`;
          }
          return item;
        });
      console.log(prdList);
      setProductList(prdListFiltered);
      setPageInfo((prev) => ({
        ...prev,
        endPage: prdList.totalPages,
        totalElements: prdList.totalElements
      }))
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const moreData = async (i) => {
    setFetching(true);
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/sotong/resourceMgmt/prductStock", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cmpnyNo: product.cmpnyNo,
          echanggoYn: "Y",
          offset: i * 40,
          pageNumber: i,
          pageSize: 40,
          paged: true,
        })
      });
      const prdList = await response.json();
      const prdListFiltered = prdList.content
        .filter((item) => item.prdImgFlpth !== null)
        .map((item) => {
          if (item.notitlePdCmPrice === null) {
            item.notitlePdCmPrice = 0;
          }
          console.log(item);
          if(!item.extrlImgUrl.includes("http")){
            item.extrlImgUrl = `https://cloud.1472.ai:18443/images/${item.extrlImgUrl}`;
          }
          return item;
        });
      setProductList((prevProductList) => [...prevProductList, ...prdListFiltered]);
    } catch (error) {
      console.error(error);
    }
    setFetching(false);
  }

  const getPortfolio = async () => {
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/portMgmt/list", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cmpnyNo: product.cmpnyNo,
          useYn: "Y",
          offset: 0,
          pageNumber: 0,
          pageSize: 10,
          paged: true
        }),
      });
      const data = await response.json();
      const dummy = data.content
      const portfolioFiltered = dummy.filter((item) => item.imgUrl.includes("images/cloud/"));
      setPortfolio(portfolioFiltered);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleClick = (product) => {
    navigate("/product-detail", {
      state: { product: product },
    });
  };

  if (isLoading) {
    return (
      <Container>
        <Row>
          <Col>
            {isLoading && (
              <div
                style={{
                  height: "300px",
                }}
              >
                <Loading />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    )
  }

  if (productList.length === 0) {
    return (
      <Container style={{ marginTop: "7em" }}>
        <Row>
          <Col>
            <h2 className="mb-4">판매중인 상품이 없습니다.</h2>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <main>
      <Row style={{ marginTop: "7rem" }}>
        <Col>
          <h2 className='mb-4'>{productList[0].cmpnyNm ?? ""} 스토어</h2>
        </Col>
      </Row>
      <ProductMap key="productmap2" prd={product} />
      <Container style={{ marginBottom: "7em" }}>
        {
          portfolio.length !== 0 ? (<PortfolioSlider portfolio={portfolio} />) : null
        }
        <div style={{ textAlign: "left", fontWeight: 800, marginTop: "3rem", fontSize: "1.4rem" }}>
          <span>총 상품 {pageInfo.totalElements} 개</span>
        </div>
        <Row>
          {productList.map((prd) => (
            <Col
              key={`${prd.prdNo}|${prd.cmpnyNo}`}
              md={3}
              xs={6}
              style={{ marginTop: "1.7rem", marginBottom: "2rem" }}
            >
              <Card
                onClick={() => handleClick(prd)}
                style={{ height: "80%", border: 0 }}>
                <Card.Img
                  variant='top'
                  src={
                    prd?.extrlImgUrl
                      ? prd.extrlImgUrl
                      : `https://cloud.1472.ai:18443/images/${prd?.prdImgFlpth}`
                  }
                  style={{ objectFit: "contain", height: "90%" }}
                />
                <Card.Body style={{ textAlign: "left", padding: 0 }}>
                  <Card.Text style={{ fontSize: "15px", marginBottom: "0.4rem", marginTop: "1rem" }}>
                    <span className="badge badge-primary" style={{ backgroundColor: "#F39802", marginRight: "0.5rem" }}>{prd?.cmpnyNm}</span>
                    <span className="badge badge-primary" style={{ border: "1px solid #F39802", color: "#F39802", backgroundColor: "white" }}>{prd.areaNm}</span>
                    {
                      prd.salesStatus === "S03" ?
                        (<span className="badge badge-primary" style={{ backgroundColor: "red", marginLeft: "0.5rem" }}>품절</span>) : (null)
                    }
                  </Card.Text>
                  <Card.Title style={{
                    fontSize: "17px",
                    fontWeight: "600",
                    marginBottom: "8px",
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: prd.salesStatus === "S03" ? "#ddd" : "black"
                  }}>{prd.prductNm}</Card.Title>
                  <Card.Text style={{ fontSize: "20px", fontWeight: "700", color: prd.salesStatus === "S03" ? "#ddd" : "black" }}>{prd.notitlePdCmPrice.toLocaleString()}원</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        {
          fetching ? (<div
            style={{
              height: "300px",
            }}
          >
            <Loading />
          </div>) : null
        }
      </Container>
    </main>
  );
};

export default CompanyProductList;