import { SlArrowLeft } from "react-icons/sl";

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <SlArrowLeft
      className={className}
      style={{ ...style, display: "block", color: "black", marginLeft: "9px" }}
      onClick={onClick}
    />
  );
}

export default SamplePrevArrow;