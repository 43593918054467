import React from 'react';
import { toast } from "react-hot-toast";


export const errorToast = (message) => {
  toast.error(message, {
    position: 'top-center',
    duration: 1000,
    style: {
      marginTop: '50px',
      background: '#ff4040',
      color: '#fff',
      fontSize: '14px',
      fontWeight: 'bold',
    },
  });
};

export const successToast = (message) => {
  toast.success(message, {
    position: 'top-right',
    duration: 2000,
    style: {
      marginTop: '100px',
      background: '#F39802',
      color: '#fff',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    iconTheme: {
      primary: 'white',
      secondary: 'black',
    },
  });
};

const Toast = () => {
  return null;
};

export default Toast;