import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Form, Table, Button } from 'react-bootstrap';
import { AiOutlineHome } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Loading from '../common/Loading';
// import MainMap from '../map/MainMap';
import ProductMap from '../map/ProductMap';
import { useTitle } from '../common/UseTitle';

const PortfolioDetail = () => {
  // const navigate = useNavigate();
  const location = useLocation();
  const portfolio = location.state.portfolio;
  const cmpnyNo = location.state.cmpnyNo;
  const [productList, setProductList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const changeTitle = useTitle();

  useEffect(() => {
    getCmpnyNm();
  }, [])

  useEffect(() => {
    let title = `${portfolio.cmpnyNm} ${portfolio.title}`
    changeTitle(title);
  }, [])

  const getCmpnyNm = async () => {
    try {
      const response = await fetch(`https://cloud.1472.ai:18443/api/v2/cmmn/cmpnyInfo/${cmpnyNo}`, {
        method: "POST",
        headers: {
          accept: "*/*"
        },
      })
      const data = await response.json();
      const product = { ...data, telNo: data.telno }
      setProductList(product);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  if (isLoading) {
    return (
      <Container>
        <Row>
          <Col>
            {isLoading && (
              <div
                style={{
                  height: "300px",
                }}
              >
                <Loading />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <main>
      <ProductMap prd={productList} />
      <Container>
        <div style={{ marginTop: "2rem", textAlign: "left" }}>
          <strong>{portfolio.contents}</strong>
        </div>
        <Row style={{ marginTop: "2rem" }}>
          <Col>
            <div>
              {
                portfolio.dtlList.map((item) => (
                  <p key={item.portDtlNo} style={{ marginBottom: "2rem" }}>
                    <img src={`https://cloud.1472.ai:18443${item.imgDtlUrl}`} style={{ width: "100%" }} />
                  </p>
                ))
              }
            </div>
            <hr />
            <div style={{ display: "flex", justifyContent: "end", marginBottom: "10rem" }}>
              <Link
                to="/portfolio"
                style={{
                  backgroundColor: "#4E515D", color: "white", padding: "3px 13px",
                  borderRadius: "3px", fontSize: "0.8rem", textDecoration: "none"
                }}
              >
                목록</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default PortfolioDetail;