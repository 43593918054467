import React from "react";
import { Link } from "react-router-dom";
import { FiHome, FiMenu, FiShoppingCart, FiUser, FiFileText } from "react-icons/fi";

import { Row, Col } from "react-bootstrap";

function MobileNavbar() {
  return (
    <Row className="d-lg-none navbar fixed-bottom navbar-light bg-light" style={{ zIndex: 9000 }}>
      <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem" }}>
        <Link to="/categories" className="nav-link">
          <FiMenu style={{ fontSize: "25px", color: 'black', strokeWidth: "1" }} />
        </Link>
      </Col>
      <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem" }}>
        <Link to="/portfolio" className="nav-link">
          <FiFileText style={{ fontSize: "25px", color: 'black', strokeWidth: "1" }} />
        </Link>
      </Col>
      <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem" }}>
        <Link to="/" className="nav-link">
          <FiHome style={{ fontSize: "25px", color: 'black', strokeWidth: "1" }} />
        </Link>
      </Col>
      <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem" }}>
        <Link to="/my-page" className="nav-link">
          <FiUser style={{ fontSize: "25px", color: 'black', strokeWidth: "1" }} />
        </Link>
      </Col>
      <Col className="text-center mx-auto" xs={2} style={{ padding: "0.5rem" }}>
        <Link to="/cart" className="nav-link">
          <FiShoppingCart style={{ fontSize: "25px", color: 'black', strokeWidth: "1" }} />
        </Link>
      </Col>
    </Row>
  );
}

export default MobileNavbar;