import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import Loading from "./common/Loading";

function FeaturedProducts({ productList }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState([true, true, true, true]);

  const handleImageLoad = (index) => {
    setIsLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = false;
      return newLoading;
    });
  };

  const handleClick = (product) => {
    navigate("/product-detail", {
      state: { product: product },
    });
  };

  return (
    <section className="py-5">
      <h2 className="text-center mb-5">인기 상품</h2>
      <Row>
        {productList.slice(5, 9).map((product, index) => (
          <Col key={index} xs={6} md={3} className="mb-5">
            <Card onClick={() => handleClick(product)} style={{ height: "80%", border: 0 }}>
              {isLoading[index] && (
                <div
                  style={{
                    height: "150px",
                  }}
                >
                  <Loading />
                </div>
              )}
              <Card.Img
                onLoad={() => handleImageLoad(index)}
                style={{
                  objectFit: "contain",
                  display: isLoading[index] ? "none" : "block",
                  height: "90%"
                }}
                src={
                  product?.prdImgFlpth
                    ? `https://cloud.1472.ai:18443/images/cloud/uploads/${product?.prdImgFlpth}`
                    : product?.extrlImgUrl
                }
              />
              <Card.Body style={{ textAlign: "left", padding: 0 }}>
                <Card.Text style={{ fontSize: "15px", color: "rgb(153, 153, 153)", marginBottom: "0.4rem", marginTop: "1rem" }}>
                  {/* {product?.infoCmpny.cmpnyNm} */}
                  <span className="badge badge-primary" style={{ backgroundColor: "#F39802", marginRight: "0.5rem" }}>{product?.cmpnyNm}</span>
                  <span className="badge badge-primary" style={{ border: "1px solid #F39802", color: "#F39802", backgroundColor: "white" }}>{product.areaNm}</span>
                  {
                    product.salesStatus === "S03" ?
                      (<span className="badge badge-primary" style={{ backgroundColor: "red", marginLeft: "0.5rem" }}>품절</span>) : (null)
                  }
                </Card.Text>
                <Card.Title style={{ fontSize: "17px", color: product.salesStatus === "S03" ? "#ddd" : "black" }}>
                  {product?.prductNm.length > 12
                    ? product?.prductNm.substring(0, 12) + ".."
                    : product?.prductNm || ""}
                </Card.Title>
                <Card.Text style={{ fontSize: "18px", fontWeight: 700, color: product.salesStatus === "S03" ? "#ddd" : "black" }}>
                  {product?.notitlePdCmPrice.toLocaleString()}원
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </section>
  );
}

export default FeaturedProducts;
