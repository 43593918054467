import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  FaTshirt,
  FaSpa,
  FaShoppingBasket,
  FaCouch,
  FaDesktop,
  FaFootballBall,
  FaBook,
  FaGlassCheers,
  FaAmbulance,
} from "react-icons/fa";
import { toast } from "react-hot-toast";

const CategoryScreen = () => {
  const navigate = useNavigate();
  const [btyList, setBtyList] = useState([]);

  useEffect(() => {
    getBtyCode();
  }, []);

  const getBtyCode = async () => {
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/code", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clCode: "BTY",
        }),
      });

      const btyList = await response.json();
      const newBtyList = btyList
        .filter((item) => item.code !== "BTY011")
        .map((item) => addBtyListIcon(item));
      console.log(newBtyList);
      setBtyList(newBtyList);
    } catch (error) {
      console.error(error);
    }
  };

  const addBtyListIcon = (item) => {
    let icon;
    let subCategories;
    switch (item.code) {
      case "BTY002":
        icon = <FaTshirt />;
        break;
      case "BTY003":
        icon = <FaSpa />;
        break;
      case "BTY004":
        icon = <FaShoppingBasket />;
        break;
      case "BTY005":
        icon = <FaCouch />;
        subCategories = 1;
        break;
      case "BTY006":
        icon = <FaDesktop />;
        break;
      case "BTY007":
        icon = <FaFootballBall />;
        break;
      case "BTY008":
        icon = <FaBook />;
        break;
      case "BTY009":
        icon = <FaGlassCheers />;
        break;
      case "BTY010":
        icon = <FaAmbulance />;
        break;
      default:
        icon = null;
    }
    return { ...item, icon, subCategories };
  };

  const handleCategory = (item) => {
    console.log(item);
    if (!item) {
      toast.error("에러가 발생하였습니다 잠시후 다시 시도해주세요.", {
        position: "top-center",
        duration: 2000,
        style: {
          marginTop: "50px",
          background: "black",
          color: "#fff",
          fontSize: "14px",
          fontWeight: "bold",
        },
      });
    } else {
      navigate("/search-result", {
        state: { cat: item.codeNm, catCode: item.code },
      });
      document.querySelector('#search').value = "";
    }
  }

  const categories = [
    { name: "식품", icon: "🍔" },
    { name: "패션", icon: "👗" },
    { name: "생활용품", icon: "🧹" },
    { name: "가전제품", icon: "📺" },
    { name: "스포츠용품", icon: "🏀" },
    { name: "미용", icon: "💄" },
  ];
  const colors = ['lightblack', 'lightblue', 'lightgreen', 'lightcoral', 'lightgrey', 'lightpink', 'lightseagreen', 'lightsalmon', 'lightsteelblue'];

  return (
    <Container style={{ marginTop: "100px", marginBottom: '5em' }}>
      <Row>
        {btyList.map((category, index) => (
          <Col xs={6} md={4} lg={3} key={index} className="mb-3">
            <Card
              style={{ height: '165px' }}
              onClick={() => handleCategory(category)}
            >
              <Card.Body className="text-center">
                <div style={{ fontSize: "50px", color: `${colors[index]}` }}>{category.icon}</div>
                <Card.Title>{category.codeNm}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default CategoryScreen;
