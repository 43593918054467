import React from 'react';
import { Container } from 'react-bootstrap';
import Terms2 from '../member/Terms2';

const PrivacyPolicy = () => {
	return (
		<Container style={{ marginTop: "12rem", marginBottom: "12rem" }}>
			<h2 style={{ marginBottom: "3rem" }}>이층창고 개인정보 처리방침</h2>
			<Terms2 />
		</Container>
	);
};

export default PrivacyPolicy;