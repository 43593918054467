import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row, Form, Table, Button } from 'react-bootstrap';
import { Link, useNavigate, useOutlet } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Modal from "../common/Modal";
import DaumPostcode from "react-daum-postcode";
import { errorToast, successToast } from "../common/Toast";

const UpdateUserInfo = () => {
  const isLoggedIn = useSelector(state => state.isLoggedIn);
  const userInfo = useSelector(state => state.user) ?? [];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [activeModal, setActiveModal] = useState(1);
  const [password, setPassword] = useState("");
  const [updatePassword, setUpdatePassword] = useState({});
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [isDeleteAddress, setIsDeleteAddress] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [checkDel, setCheckDel] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [resultAddress, setResultAddress] = useState({});

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, []);

  const handleCloseAddressModal = () => {
    setActiveModal(1);
    setIsAddressModalOpen(false);
  }

  const handleCloseDeleteAddress = () => {
    setIsDeleteAddress(false);
  }

  const handleClosePostModal = () => {
    setIsPostModalOpen(false);
  }

  const handleCloseUpdateModal = () => {
    dispatch({ type: "LOGOUT" });
  }

  const formattedNumber = userInfo.length === 0 ? "" : userInfo.moblphonNo.includes('-') ? userInfo.moblphonNo : userInfo.moblphonNo.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');


  // 비밀번호 확인
  const checkPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/auth/signIn", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          idOrEmail: userInfo.userName,
          password: password,
        })
      });
      const data = await response.json();
      console.log(data);
      if (data.success === false) {
        errorToast("비밀번호가 올바르지 않습니다.");
      } else {
        setActiveTab(2);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 개인정보 변경 api
  const updateUserInfo = async (e) => {
    e.preventDefault();

    const validPhNum = phoneRegExp.test(e.target.telNo.value);
    let newData = {
      mberNo: userInfo.mberNo,
      cmpnyNo: userInfo.cmpnyNo,
      username: userInfo.userName,
      mberNm: userInfo.name,
      // rgsCl: "MBRS03",
      delYn: "N"
    };

    if (!e.target.email.value) {
      newData = { ...newData, email: userInfo.email }
    } else {
      if (validEmailCheck(e.target.email.value) == false) {
        errorToast("이메일이 올바르지 않습니다.")
        return;
      } else {
        newData = { ...newData, email: e.target.email.value, }
      }
    }

    if (!e.target.telNo.value) {
      newData = { ...newData, moblphonNo: userInfo.moblphonNo }
    } else {
      if (!validPhNum) {
        errorToast("올바른 핸드폰 번호를 입력하세요.")
        return;
      } else {
        newData = { ...newData, moblphonNo: e.target.telNo.value, }
      }
    }

    if (!updatePassword.password && !updatePassword.confirmPassword) {
      console.log("입력확인");
    } else {
      if (updatePassword.password === updatePassword.confirmPassword) {
        newData = {
          ...newData, password: updatePassword.password
        }
      } else {
        errorToast("비밀번호가 일치하지 않습니다.");
        return;
      }
    }

    try {
      const response = await fetch("https://cloud.1472.ai:18443/api/v2/mberMgmt/mber", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData)
      });
      const data = await response.json();
      console.log(data);
      if (data.success) {
        setIsUpdateModalOpen(true);
      } else {
        errorToast("변경에 실패했습니다.");
      }
    } catch (error) {
      console.error(error);
    }
  }

  const openAddressModla = () => {
    // 배송지 조회 api
    fetch("https://cloud.1472.ai:18443/api/v2/mberMgmt/mberDlvySpt", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mberNo: userInfo.mberNo,
        useYn: "Y",
        offset: 0,
        pageNumber: 0,
        pageSize: 5,
        paged: true
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        const dummy = data.content
        setAddressList(dummy.map((item) => item));
        setIsAddressModalOpen(true)
      })
      .catch((error) => console.error(error))
  }

  // 배송지 삭제
  const deleteAddress = (dlvySptNo) => {
    fetch("https://cloud.1472.ai:18443/api/v2/mberMgmt/mberDlvySpt", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dlvySptNo: dlvySptNo,
        mberNo: userInfo.mberNo,
        useYn: "N"
      })
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        setIsAddressModalOpen(false)
        setCheckDel("");
        successToast("배송지가 삭제되었습니다.")
      })
      .catch((error) => console.error(error))
  }

  // 주소 저장시 실행
  const saveAddress = (e) => {
    e.preventDefault();
    const validPhNum = phoneRegExp.test(e.target.telNum.value);
    if (!validPhNum) {
      errorToast("올바른 핸드폰 번호를 입력하세요.")
      return;
    }
    if (!e.target.address.value) {
      errorToast("배송지를 확인하세요.")
      return;
    }
    // 배송지 저장 api
    fetch("https://cloud.1472.ai:18443/api/v2/mberMgmt/mberDlvySpt", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mberNo: userInfo.mberNo,
        dlvySptNm: e.target.delyName.value,
        zip: resultAddress.zonecode,
        roadAdres: resultAddress.address,
        lnmAdres: resultAddress.jibunAddress,
        dtlAdres: e.target.detailAddress.value,
        receiveName: e.target.name.value,
        receivePhone: e.target.telNum.value,
        useYn: "Y"
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        successToast("배송지가 추가되었습니다.")
      })
      .catch((error) => console.error(error))
    handleCloseAddressModal();
    setActiveModal(1);
    setResultAddress({});
  }

  // 주소 검색시 실행
  const handleComplete = (data) => {
    console.log(data)
    // 결과저장
    setResultAddress(
      { ...resultAddress, address: data.roadAddress, zonecode: data.zonecode, jibunAddress: data.jibunAddress }
    );
    // Input 결과저장
    setIsPostModalOpen(false)
  };

  // 번호 유효성
  const phoneRegExp = /^(01[0]{1}|02|0[3-9]{1}[0-9]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

  // 이메일 확인
  const validEmailCheck = (email) => {
    let pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return (email.match(pattern) != null);
  }

  return (
    <Container className="py-5" style={{ marginTop: "5em", marginBottom: '5em' }}>
      <h2>개인정보수정</h2>
      <Row>
        <Col md={6} style={{ margin: "0 auto" }}>
          {activeTab === 1 && (
            <Card style={{ maxWidth: "900px", height: "250px", margin: "0 auto", display: "flex", justifyContent: "space-evenly" }}>
              <span style={{ fontWeight: "700" }}>
                개인정보 수정을 위해 비밀번호를 입력해 주세요.
              </span>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ whiteSpace: "nowrap", fontWeight: "700" }}>비밀번호</span>
                <Form onSubmit={checkPassword}>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    style={{ width: "230px", marginLeft: "20px" }} />
                  <Button
                    variant="primary"
                    style={{ backgroundColor: "#F39802", border: 0, width: "100px", marginTop: "20px" }}
                    type="submit">
                    확인</Button>
                </Form>
              </div>
            </Card>
          )}
          {activeTab === 2 && (
            <>
              <Form onSubmit={updateUserInfo}>
                <Table>
                  <tbody style={{ textAlign: "left" }}>
                    <tr>
                      <td style={{ borderRight: "2px solid #EFF1F3", backgroundColor: "#EEEFF1" }}>
                        이름
                      </td>
                      <td>
                        {userInfo.name}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderRight: "2px solid #EFF1F3", backgroundColor: "#EEEFF1" }}>
                        아이디
                      </td>
                      <td>
                        {userInfo.userName}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderRight: "2px solid #EFF1F3", backgroundColor: "#EEEFF1" }}>
                        회사명
                      </td>
                      <td>
                        {userInfo.cmpnyNm}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderRight: "2px solid #EFF1F3", backgroundColor: "#EEEFF1" }}>
                        비밀번호
                      </td>
                      <td>
                        <Form.Control style={{ maxWidth: "285px" }}
                          type="password"
                          onChange={(e) => {
                            setUpdatePassword({ ...updatePassword, password: e.target.value });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderRight: "2px solid #EFF1F3", backgroundColor: "#EEEFF1" }}>
                        비밀번호 확인
                      </td>
                      <td>
                        <Form.Control style={{ maxWidth: "285px" }}
                          type="password"
                          onChange={(e) => {
                            setUpdatePassword({ ...updatePassword, confirmPassword: e.target.value });
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderRight: "2px solid #EFF1F3", backgroundColor: "#EEEFF1" }}>
                        이메일
                      </td>
                      <td>
                        <Form.Control style={{ maxWidth: "285px" }}
                          defaultValue={userInfo.email}
                          name="email"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderRight: "2px solid #EFF1F3", backgroundColor: "#EEEFF1" }}>
                        전화번호
                      </td>
                      <td style={{ display: "flex" }}>
                        <Form.Control style={{ maxWidth: "285px" }}
                          defaultValue={formattedNumber}
                          name="telNo"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ borderRight: "2px solid #EFF1F3", backgroundColor: "#EEEFF1" }}>
                        배송지 관리
                      </td>
                      <td style={{ borderBottom: "1px solid #EFF1F3" }}>
                        <Button
                          variant="primary"
                          style={{ backgroundColor: "#F39802", border: 0, width: "100px", whiteSpace: "nowrap" }}
                          onClick={() => {
                            openAddressModla();
                          }}>
                          배송지 관리</Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Button
                  variant="primary"
                  style={{ backgroundColor: "#F39802", border: 0, width: "110px" }}
                  type="submit"
                >
                  저장</Button>
              </Form>
            </>
          )}
        </Col>
      </Row>

      {/* 배송지 목록 */}
      <Modal isOpen={isAddressModalOpen} onClose={handleCloseAddressModal} title="배송지 목록" name="list">
        {activeModal === 1 && (
          <>
            {
              addressList.length > 0 ? (
                <>
                  {addressList.map((item) => (
                    <Card style={{ textAlign: "left" }} key={item.dlvySptNo} className="mb-4">
                      <Card.Header>{item.dlvySptNm}</Card.Header>
                      <Card.Body>
                        <Card.Text>
                          {item.lnmAdres} &nbsp;&nbsp;{item.dtlAdres} ({item.roadAdres})
                        </Card.Text>
                        <Card.Text>{item.receiveName}&nbsp;&nbsp;&nbsp;{item.receivePhone}</Card.Text>
                        <Button
                          variant="light"
                          size="sm"
                          style={{ position: "absolute", right: 0, bottom: 10, marginRight: "10px", color: "#F39802", border: "1px solid #F39802" }}
                          onClick={() => {
                            setCheckDel(item.dlvySptNo);
                            setIsDeleteAddress(true);
                          }}
                        >
                          삭제
                        </Button>
                      </Card.Body>
                    </Card>
                  ))
                  }
                </>
              ) : (
                <>
                  <span style={{ display: "block", marginBottom: "20px" }}>배송지가 없습니다.</span>
                </>
              )
            }
            <Button
              variant="primary"
              style={{ backgroundColor: "#F39802", border: 0 }}
              onClick={() => {
                setActiveModal(2);
              }}>배송지 추가</Button>
          </>
        )}
        {activeModal === 2 && (
          <>
            <Form className="text-left" onSubmit={saveAddress}>
              <Form.Group controlId="receiverName" className="mb-3">
                <Form.Label>이름</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="이름을 입력하세요"
                  required
                />
              </Form.Group>
              <Form.Group controlId="receiverPhone" className="mb-3">
                <Form.Label>전화번호</Form.Label>
                <Form.Control
                  type="text"
                  name="telNum"
                  placeholder="전화번호를 입력하세요"
                  required
                />
              </Form.Group>
              <Form.Group controlId="receiverDelyName" className="mb-3">
                <Form.Label>배송지명</Form.Label>
                <Form.Control
                  type="text"
                  name="delyName"
                  placeholder="배송지 이름을 입력하세요"
                  required
                />
              </Form.Group>
              <Form.Group controlId="receiverAddress" className="mb-3" style={{ display: "inline-block" }}>
                <Form.Label>배송지 주소</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  placeholder="배송지 주소를 입력하세요"
                  readOnly
                  required
                  value={resultAddress.address ?? ""}
                />
              </Form.Group>
              <Button
                style={{ backgroundColor: "#F39802", border: 0 }}
                onClick={() => {
                  setIsPostModalOpen(true)
                }}
              >주소검색
              </Button>
              <Form.Group controlId="detailAddress" className="mb-3">
                <Form.Label>상세주소</Form.Label>
                <Form.Control
                  type="text"
                  name="detailAddress"
                  placeholder="상세주소"
                  required
                />
              </Form.Group>

              <Button
                style={{ backgroundColor: "#F39802", border: 0, float: "right" }}
                type="submit"
              >저장</Button>
            </Form>
          </>
        )}
        <Button variant="light" className="mr-2" onClick={() => {
          handleCloseAddressModal()
          setActiveModal(1);
        }} style={{ position: "absolute", top: 0, right: 0 }} >X</Button>
      </Modal>

      {/* 배송지삭제 */}
      <Modal isOpen={isDeleteAddress} onClose={handleCloseDeleteAddress} title="배송지 삭제" name="common">
        <p>삭제하시겠습니까?</p>
        <Button
          variant="light"
          style={{ color: "#F39802", border: "1px solid #F39802" }}
          className="mr-2"
          onClick={() => {
            setCheckDel("");
            handleCloseDeleteAddress();
          }}
        >
          취소
        </Button>
        <Button
          variant="primary"
          style={{ backgroundColor: "#F39802", border: 0 }}
          onClick={() => {
            deleteAddress(checkDel);
            setIsDeleteAddress(false);
          }}
        >
          확인
        </Button>
      </Modal>

      {/* 주소검색 */}
      <Modal isOpen={isPostModalOpen} onClose={handleClosePostModal} title="주소검색" name="common">
        <DaumPostcode onComplete={handleComplete} />
        <Button variant="light" className="mr-2" onClick={() => {
          handleClosePostModal()
        }} style={{ position: "absolute", top: 0, right: 0 }} >X</Button>
      </Modal>

      {/* 변경완료 */}
      <Modal isOpen={isUpdateModalOpen} onClose={handleCloseUpdateModal} title="변경완료" name="common">
        <h4>변경완료!</h4>
        <span style={{ display: "block", marginBottom: "20px" }}>다시 로그인 해주세요.</span>
        <Button
          variant="primary"
          style={{ backgroundColor: "#F39802", border: 0 }}
          onClick={() => {
            dispatch({ type: "LOGOUT" });
          }}
        >확인</Button>
        <Button variant="light" className="mr-2" onClick={() => {
          dispatch({ type: "LOGOUT" });
        }} style={{ position: "absolute", top: 0, right: 0 }} >X</Button>
      </Modal>
    </Container>
  );
};

export default UpdateUserInfo;