import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Table,
  Card,
  Form,
  Button
} from "react-bootstrap";
import { useSearchParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs'
import { useSelector } from "react-redux";
import Loading from '../common/Loading';

function Success() {
  const userInfo = useSelector(state => state.user) ?? [];
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams()
  const [payments, setPayments] = useState([]);
  const navigate = useNavigate();
  const [ord, setOrd] = useState([]);
  const [items, setItems] = useState([]);
  const orderId = searchParams.get("orderId");
  const paymentKey = searchParams.get("paymentKey")
  const amount = searchParams.get("amount")
  const secretKey = 'test_sk_OEP59LybZ8Bdy9Q1kRWV6GYo7pRe:'
  const ordNo = orderId.split("_");

  useEffect(() => {
    ordNo.shift();
    paymentsConfirm();
  }, []);

  // 결제승인
  const paymentsConfirm = async () => {
    try {
      const response = await fetch("https://api.tosspayments.com/v1/payments/confirm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(secretKey),
        },
        body: JSON.stringify({
          paymentKey: paymentKey,
          orderId: orderId,
          amount: amount,
        }),
      });
      const data = await response.json();
      console.log(data);
      getPayments();
    } catch (error) {
      console.error(error);
    }
  };

  // 결제정보
  const getPayments = async () => {
    try {
      const response = await fetch(`https://api.tosspayments.com/v1/payments/orders/${orderId}`, {
        method: "GET",
        headers: {
          "Authorization": "Basic " + btoa(secretKey)
        },
      });
      const data = await response.json();
      console.log(data);
      setPayments(data);
      orderList();
    } catch (error) {
      console.error(error);
    }
  };

  const orderList = async () => {
    let ordDummy = [];
    try {
      await Promise.all(ordNo.map(async (item) => {
        const response = await fetch("https://erp.1472.ai:28443/api/v1/ordDtlList", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ordNo: item,
            offset: 0,
            pageNumber: 0,
            pageSize: 10,
            paged: true
          })
        });
        const data = await response.json();
        const dummy = data.content;
        const newList = await Promise.all(dummy);

        newList.map((item) => {
          setItems((list) => [...list, item])
        })

        const response2 = await fetch("https://erp.1472.ai:28443/api/v1/ordInqry", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ordNo: item,
            offset: 0,
            pageNumber: 0,
            pageSize: 10,
            paged: true
          })
        })
        const data2 = await response2.json();
        console.log(data2);
        ordDummy.push(data2.content[0]);
        setOrd((list) => [...list, data2.content[0]]);
      }))

      const nowTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
      await Promise.all(ordNo.map(async (item) => {

        const response = await fetch("https://erp.1472.ai:28443/api/v1/ordToss", {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ordVo: {
              ordNo: item,
              ordDt: nowTime,
              progrsSttus: "OWS012",
              tossOrdId: paymentKey
            }
          })
        });
        const data = await response.json();
        console.log(data);
      }))
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const phoneNumber = (num) => {
    if (num.length === 11) {
      return num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
    return num;
  }

  const trnsprtDvyfgAm = () => {
    let amount = 0;
    ord.map((item) => {
      amount = amount + item.trnsprtDvyfgAm;
    })
    return amount;
  }

  const demandTot = () => {
    let amount = 0;
    ord.map((item) => {
      amount = amount + item.demandTot;
    })
    return amount;
  }

  const renderBank = () => {
    let render = "";
    switch (payments.method) {
      case "가상계좌":
        render = bankCode[`${payments.virtualAccount.bankCode}`];
        break;
      case "간편결제":
        render = payments.easyPay.provider;
        break;
      case "카드":
        render = cardCode[`${payments.card.acquirerCode}`];
        break;
      default:
        render = "정보가없습니다.";
    }

    return render;
  }

  if (isLoading) {
    return (
      <Container>
        <Row>
          <Col>
            {isLoading && (
              <div
                style={{
                  height: "300px",
                }}
              >
                <Loading />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container
      className="py-5"
      style={{ marginTop: "5em", marginBottom: "5em" }}
    >
      <h2>주문완료</h2>

      <Row className="mb-5">
        <Col>
          <h4 className="text-left">상품정보</h4>
          <Card style={{ border: 0 }}>
            <Table hover responsive>
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>이미지</th>
                  <th style={{ width: "60%" }}>상품명</th>
                  <th style={{ whiteSpace: "nowrap" }}>수량</th>
                  <th style={{ whiteSpace: "nowrap" }}>상품금액</th>
                </tr>
              </thead>
              <tbody>
                {
                  items.map((item) => (
                    <tr key={item.prdNo}>
                      <td><img style={{ width: 100 }} src={`https://cloud.1472.ai:18443/images/cloud/uploads/${item.repImg}`} /></td>
                      <td className='text-center'>{item.prductNm}</td>
                      <td>{item.qy}</td>
                      <td className='text-center'>{(item.totAmount).toLocaleString()}원</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h4 className="text-left">주문정보</h4>
          <Card style={{ border: 0 }}>
            <Card.Body>
              <Table responsive className="text-center">
                <tbody>
                  <tr>
                    <td colSpan={1} style={{ width: "24%", whiteSpace: "nowrap" }}>주문일자</td>
                    <td colSpan={7} className="text-right">
                      {ord[0]?.dedtDe ? ord[0].dedtDe : ""}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1} style={{ width: "24%", whiteSpace: "nowrap" }}>이름</td>
                    <td colSpan={7} className="text-right">
                      {ord[0]?.rcpnt ? ord[0].rcpnt : ""}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>번호</td>
                    <td colSpan={7} className="text-right">
                      {phoneNumber(ord[0].bcncTelno)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>배송지</td>
                    <td colSpan={7} className="text-right">
                      {ord[0]?.sptNm ? ord[0].sptNm : ""}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={1}>상태</td>
                    <td colSpan={7} className="text-right">
                      결제완료
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-5">
        <Col>
          <h4 className="text-left">결제정보</h4>
          <Table responsive className="text-center">
            <tbody>
              <tr>
                <td>
                  <div style={{ whiteSpace: "nowrap", display: "flex", flexDirection: "column", width: "20vw" }}>
                    <span>결제수단</span>
                    <strong> {payments.method} / {renderBank()}</strong>
                  </div>
                </td>
                <td style={{ width: "17rem" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>총 상품가격</span>
                    <strong>{demandTot().toLocaleString()}원</strong>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>배송비</span>
                    <span>{trnsprtDvyfgAm().toLocaleString()}원</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>결제수단</span>
                    <span>{payments.method}</span>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>총 결제금액</span>
                    <strong>{(demandTot() + trnsprtDvyfgAm()).toLocaleString()}원</strong>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Button
        variant="primary"
        style={{ backgroundColor: "#F39802", border: 0 }}
        onClick={() => {
          navigate("/my-page");
        }}
      >목록으로</Button>
    </Container>
  );
};

export default Success;